<h1 class="page-title">Produkciós alapértékek</h1>
<main class="main-container">

  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <section class="form-group">
      <h1 class="title subtitle3">{{ form.get('prname')?.getRawValue() }}</h1>
      <mat-form-field class="form-field " appearance="outline">
        <mat-label>Produkció címe</mat-label>
        <input matNativeControl formControlName="prname">
        <mat-error *ngIf="form?.hasError('required', 'prname')">
          A megadása <strong>kötelező</strong>
        </mat-error>
      </mat-form-field>
    </section>
  </form>

  <form [formGroup]="exchRatesForm">
    <section class="currency-exchange-rates form-group">
      <h1 class="title" t7e="currency-exchange-rates">Árfolyamok</h1>
    
      <div class="currency-exchange-rates-inputs" formArrayName="exchangeRates">
        <div *ngIf="debug">{{ days$ | async | json }}</div>
        <!-- <mat-spinner [diameter]="15" *ngIf="daysLoading$ | async"></mat-spinner> -->
        <!-- {{ exchangeRatesFA.getRawValue() | json }} -->
        <div class="day" *ngFor="let day of exchangeRatesFA.controls; let i=index; trackBy:trackByIndex" [formGroupName]="i">
        <!-- {{day.getRawValue() | json }} -->
          <div class="date">{{ day.value.date | date: 'yyyy.MM.dd.' }}</div>
          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>1 EUR = </mat-label>
            <input matNativeControl formControlName="rate" type="number" min="0" step="5">
            <span matSuffix class="currency">&nbsp;HUF</span>
            <mat-error *ngIf="form?.hasError('required', 'eur')">
              A megadása <strong>kötelező</strong>
            </mat-error>
          </mat-form-field>
          <button mat-icon-button (click)="deleteExchangeRate(day.value)" [disabled]="(daySaving$ | async) || (daysLoading$ | async)" type="button">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
    
        <button type="button" (click)="saveExchangeRates()" mat-raised-button color="primary"
          *ngIf="exchangeRatesFA.controls?.length" [disabled]="(daySaving$ | async) || (daysLoading$ | async)">
          <mat-spinner [diameter]="15" *ngIf="(daySaving$ | async) || (daysLoading$ | async)"></mat-spinner>
          <span t7e="save">Mentés</span>
        </button>
    
      </div>
    
      <div class="currency-exchange-rates-new">
        <!-- new date -->
        <mat-form-field class="form-field input-date" appearance="outline">
          <mat-label>Új árfolyam dátuma</mat-label>
          <input matInput [matDatepicker]="dNewExchangeRateDate" formControlName="dNewExchangeRateDate" 
            (keydown.enter)="newExchangeRateChanged($event)">
          <mat-datepicker-toggle matSuffix [for]="dNewExchangeRateDate"></mat-datepicker-toggle>
          <mat-datepicker #dNewExchangeRateDate></mat-datepicker>
        </mat-form-field>
        <!-- new rate -->
        <mat-form-field class="form-field input-number" appearance="outline">
          <mat-label>1 EUR = </mat-label>
          <input matNativeControl formControlName="newExchangeRate" type="number" min="0" step="5"
            (keydown.enter)="newExchangeRateChanged($event)">
          <span matSuffix class="currency">&nbsp;HUF</span>
        </mat-form-field>
    
        <button type="button" (click)="addExchangeRate()" mat-raised-button color="primary" 
          [disabled]="(daySaving$ | async) || (daysLoading$ | async)">
          <mat-spinner [diameter]="15" *ngIf="daySaving$ | async"></mat-spinner>
          <span t7e="save">Mentés</span>
        </button>
      </div>

      <div class="save-load-error mb-2" *ngIf="((saveError$ | async) || (daysLoadingError$ | async)) as error">
        <span>{{ error }}</span>
      </div>
    </section>
  </form>

  <form [formGroup]="locationsForm">
    <section class="locations form-group">
          <h1 class="title" t7e="locations">Helyszínek</h1>
      <app-locations [locationsFG]="locationsFG"></app-locations>
    </section>
  </form>

  <form [formGroup]="holidaysForm">
    <section class="holidays form-group">
      <h1 class="title" t7e="holidays">Ünnepnapok</h1>
      <app-holidays [holidaysFG]="holidaysFG"></app-holidays>
    </section>
  </form>
  
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <section class="dates form-group">
      <h1 class="title">Dátumok</h1>
      <div class="date-inputs">
        <mat-form-field class="input-date" appearance="outline">
          <mat-label>Előkészítés kezdete</mat-label>
          <input matInput [matDatepicker]="dPreparationstartday" formControlName="dPreparationstartday">
          <mat-datepicker-toggle matSuffix [for]="dPreparationstartday"></mat-datepicker-toggle>
          <mat-datepicker #dPreparationstartday></mat-datepicker>
          <mat-hint>Ez előtti dátumra nem fogadunk el timesheetet</mat-hint>
          <mat-error *ngIf="form?.hasError('required', 'dPreparationstartday')">
            A megadása <strong>kötelező</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field input-date" appearance="outline">
          <mat-label>Forgatás kezdete</mat-label>
          <input matInput [matDatepicker]="dShootingstart" formControlName="dShootingstart">
          <mat-datepicker-toggle matSuffix [for]="dShootingstart"></mat-datepicker-toggle>
          <mat-datepicker #dShootingstart></mat-datepicker>
          <mat-hint>
            Mikor kezdődik a forgatás? Van, aki más napidíjat kap a forgatásra, mint az előkészítésre
          </mat-hint>
          <mat-error *ngIf="form?.hasError('required', 'dShootingstart')">
            A megadása <strong>kötelező</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field input-date" appearance="outline">
          <mat-label>Forgatás vége</mat-label>
          <input matInput [matDatepicker]="dShootingend" formControlName="dShootingend">
          <mat-datepicker-toggle matSuffix [for]="dShootingend"></mat-datepicker-toggle>
          <mat-datepicker #dShootingend></mat-datepicker>
          <mat-hint>
            Mikor végződik a produkció? Ez utáni dátumra nem fogadunk el
            timesheetet
          </mat-hint>
          <mat-error *ngIf="form?.hasError('required', 'dShootingend')">
            A megadása <strong>kötelező</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </section>

    <section class="fees form-group">
      <h1 class="title">Alapértelmezett díjak</h1>
      <div class="fees-inputs">

        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Elszámolás pénzneme</mat-label>
          <input type="text" matInput formControlName="currency" [matAutocomplete]="currency">
          <mat-autocomplete #currency="matAutocomplete">
            <mat-option *ngFor="let option of ['HUF', 'EUR']" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-hint>Ez az alapértelmezett pénznem. A szerződéseknél ezt felül lehet bírálni</mat-hint>
          <mat-error *ngIf="form?.hasError('required', 'currency')">
            A megadása <strong>kötelező</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field input-number" appearance="outline">
          <mat-label>A teljes OT + TA költségvetési összeg</mat-label>
          <input matNativeControl formControlName="ottabudget" type="number" min="0" step="100000">
          <span matSuffix class="currency">&nbsp;{{ form.get('currency')?.getRawValue() }}</span>
          <mat-hint>A jelentésben ehhez képest látod az elköltött pénzt százalékosan</mat-hint>
          <mat-error *ngIf="form?.hasError('required', 'ottabudget')">
            A megadása <strong>kötelező</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field input-number" appearance="outline">
          <mat-label>Munkanap hossza</mat-label>
          <input matNativeControl formControlName="workhours" type="number" min="0" max="24" step="1">
          <span matSuffix class="currency">&nbsp;óra</span>
          <mat-hint>Alapértelmezett munkanap hossza órában. Általában 10 vagy 12 óra</mat-hint>
          <mat-error *ngIf="form?.hasError('required', 'workhours')">
            A megadása <strong>kötelező</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field input-number" appearance="outline">
          <mat-label>Per Diem díj</mat-label>
          <input matNativeControl formControlName="sleepoverrate" type="number" min="0" step="500">
          <span matSuffix class="currency">&nbsp;{{ form.get('currency')?.getRawValue() }}</span>
          <mat-hint>Az extra díj a napidíj felett</mat-hint>
          <mat-error *ngIf="form?.hasError('required', 'sleepoverrate')">
            A megadása <strong>kötelező</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field input-number" appearance="outline" *ngIf="MEAL_PENALTY=='setAtProdDeptSf'">
          <mat-label>Meal Penalty teljes órára</mat-label>
          <input matNativeControl formControlName="mealPenaltyRate" type="number" min="0" step="500">
          <span matSuffix class="currency">&nbsp;{{ form.get('currency')?.getRawValue() }}</span>
          <mat-hint>A Meal Penalty alapértéke</mat-hint>
        </mat-form-field>

      </div>


      <div class="otrates">

        <h2>OT</h2>

        <div class="otrate-horizontal-fields">
          <!-- grace period -->
          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>Grace period</mat-label>
            <input matNativeControl formControlName="graceperiod" type="number" min="0" max="60" step="5">
            <span matSuffix class="currency" t7e="minute">&nbsp;perc</span>
            <mat-hint>Ennyi percet nem számítunk túlórába</mat-hint>
          </mat-form-field>
          
          <!-- OT lépcső -->
          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>OT lépcső</mat-label>
            <input matNativeControl formControlName="overtimestep" type="number" min="0.25" max="4" step="0.25">
            <span matSuffix class="currency">&nbsp;óra</span>
            <mat-hint>Hány óránként ugrik az OT számítás</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'overtimestep')">
              A megadása <strong>kötelező</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <app-ot-rate-inputs [form]="form" [otratesFA]="otratesFA" dataLevel="prod">
        </app-ot-rate-inputs>
      </div>

      <div class="tarates">

        <h2>TA</h2>

        <div class="tarate-inputs">

          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>TA lépcső</mat-label>
            <input matNativeControl formControlName="tastep" type="number" min="0" max="4" step="0.25">
            <span matSuffix class="currency">&nbsp;óra</span>
            <mat-hint>Hány óránként ugrik a TA számítás</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'tastep')">
              A megadása <strong>kötelező</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>TA óradíj</mat-label>
            <input matNativeControl formControlName="tarate" type="number" min="0" step="500">
            <span matSuffix class="currency">&nbsp;{{ form.get('currency')?.getRawValue() }}</span>
            <mat-hint>A pihenőidő megváltás óradíja</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'tarate')">
              A megadása <strong>kötelező</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>Egybefüggő munkanapok száma</mat-label>
            <input matNativeControl formControlName="maxworkdayperiod" type="number" min="0" max="100" step="1">
            <span matSuffix class="currency">&nbsp;nap</span>
            <mat-hint>Hány munkanap után jár pihenőnap</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'maxworkdayperiod')">
              A megadása <strong>kötelező</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>Rövid pihenőidő</mat-label>
            <input matNativeControl formControlName="shortresthours" type="number" min="0" max="24" step="1">
            <span matSuffix class="currency">&nbsp;óra</span>
            <mat-hint>Hány óra pihenő jár két munkanap között</mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'shortresthours')">
              A megadása <strong>kötelező</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field input-number" appearance="outline">
            <mat-label>Hosszú pihenőidő</mat-label>
            <input matNativeControl formControlName="longresthours" type="number" min="11" max="48" step="1">
            <span matSuffix class="currency">&nbsp;óra</span>
            <mat-hint>
              Hány óra pihenő jár a {{ form.get('maxworkdayperiod')?.getRawValue() || 6 }} munkanap után
            </mat-hint>
            <mat-error *ngIf="form?.hasError('required', 'longresthours')">
              A megadása <strong>kötelező</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="meal-penalty">
          <h2>Meal Penalty</h2>

          <div class="meal-penalty-value">
            Jelenlegi beállítás: {{ MEAL_PENALTY || 'nincs'}}
          </div>
      </div>
    </section>

    <section class="form-group">
      <h1>Kódok</h1>
      <div class="codes">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>OT/TA kód</mat-label>
          <input matNativeControl formControlName="codeOtta">
          <mat-hint>Csak ha mindenkinek megegyezik</mat-hint>
        </mat-form-field>
        
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>km kód</mat-label>
          <input matNativeControl formControlName="codeGas">
          <mat-hint>Csak ha mindenkinek megegyezik</mat-hint>
        </mat-form-field>
        
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Parkolás kód</mat-label>
          <input matNativeControl formControlName="codePark">
          <mat-hint>Csak ha mindenkinek megegyezik</mat-hint>
        </mat-form-field>
        
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Matrica kód</mat-label>
          <input matNativeControl formControlName="codeVignette">
          <mat-hint>Csak ha mindenkinek megegyezik</mat-hint>
        </mat-form-field>
      </div>
    </section>

    <section class="form-field save-buttons ">
      <div *ngIf="debug">form.valid:{{ form.valid }}; form.touched: {{ form.touched }}</div>
      <app-save-button color="primary" [disabled]="!debug && (!form.valid || !form.touched)" [saveError$]="saveError$"
        errorText="A mentés nem sikerült!" [showErrMsg]="true" successText="A mentés sikerült!">
      </app-save-button>

      <button type="button" (click)="test()" *ngIf="debug">TEST</button>
    </section>

  </form>
</main>