import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { BehaviorSubject, tap } from 'rxjs';
import { GrandOtta, GrandOttaCateg } from '../models/grand-otta';
import { AppService, DbCallError } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  otta$ = new BehaviorSubject<GrandOtta | null>(null)
  isGrandOttaLoading$ = new BehaviorSubject<boolean>(false)

  weeklyOtta$ = new BehaviorSubject<GrandOtta | null>(null)
  isWeeklyOttasLoading$ = new BehaviorSubject<boolean>(false)
  weeklyOttasError$ = new BehaviorSubject<DbCallError>(null)

  constructor(
    private dataSvc: DataService,
    private appSvc: AppService,
    ) { }

  getGrandOtta() {
    this.isGrandOttaLoading$.next(true)
    const retVal = this.dataSvc.getGrandOtta()
    retVal.subscribe({
      next: otta => {
        this.isGrandOttaLoading$.next(false)
        this.otta$.next(otta);
      },
      error: err => {
        this.isGrandOttaLoading$.next(false)
      }
    })
    return retVal
  }

  getWeeklyOttas(from: moment.Moment, to: moment.Moment) {
    //console.log(from, to)
    this.isWeeklyOttasLoading$.next(true)
    const retVal = this.dataSvc.getGrandOtta(GrandOttaCateg.Weekly, from, to)
    retVal
      .pipe(
        tap(() => this.isWeeklyOttasLoading$.next(false))
      )
      .subscribe({
        next: otta => {
          const allOttaPeriods = this.weeklyOtta$.value
            ? this.appSvc.replaceDocItems(otta.periods, this.weeklyOtta$.value.periods, 'period')
            : null
          if (allOttaPeriods) {
            otta.periods = allOttaPeriods
          }
          otta.periods.sort((p1, p2) => p1.period.localeCompare(p2.period))
          this.weeklyOtta$.next(otta)
          this.weeklyOttasError$.next(false)
        },
        error: err => {
          this.weeklyOttasError$.next(err)
        }
      })
    return retVal
  }

  getDailyOttasForWeek(startdate: moment.Moment) {
    const enddate = startdate.clone().add(1, 'week');
    return this.dataSvc.getGrandOtta(GrandOttaCateg.Daily, startdate, enddate);
  }
}
