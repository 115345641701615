import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, share, tap } from 'rxjs';
import { DbCallError, SESSION_STORAGE_IMPERSONATE } from './app.service';
import { Contract, DocLink } from './models/contract';
import { DbGroupMembership, AppUser } from './models/db-user';
import { Department, HandleDeptsParams } from './models/department';
import { FieldHistory, FieldHistoryParsed, ListItemHistoryParams } from './models/field-history';
import { HandleContractsParams, ListContractsParams } from './models/handle-contracts-params';
import { HandleFlexPropertiesParams, HandleFlexTablePropertiesParams, HandlePropItemsParams } from './models/handle-table-properties-params';
import { HandleUserGroupMembersParams, HandleUsersParameters, ListUsersParameters } from './models/handle-users-parameters';
import { HandleProdParameters, ListProdParameters, Production } from './models/production';
import { FlexProp, FlexPropWithValue, FlexTable, FlexTableProperty, PropItem } from './models/table-property';
import { CalcSalaryParams, CalculatedFeesForDay, CheckTimesheetParams, CheckTimesheetResponse, HandleTimesheetsParams, ListNotInvoicedTsParams, ListNotInvoicedTs, ListTimesheetsParams, RegisterTsStatus, RegisterTsStatusResponse, Timesheet } from './models/timesheet';
import { TsCalcInfo } from './models/ts-calc-info';
import { CrewListItem, ListCrewParams } from './models/crew-list';
import { GrandOtta, GrandOttaCateg, GrandOttaParams, GrandOttaResponse, parseGrandOtta } from './models/grand-otta';
import { CalcSalaryResponseSql, ParserService, SQL_DATETIME_FORMAT, SQL_DATE_FORMAT, SQL_TIMEZONE } from './parser.service';
import { environment } from './../environments/environment';
import { Dispo, DispoParams, HandleDispoParams } from './models/dispo';
import { Moment } from 'moment-timezone';
import { HandleShootingLocationParams, ShootingLocation } from './models/location';
import { SendMailParams } from './models/mail';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  readonly apiUrl = 'https://europe-west1-crewtime-teszt.cloudfunctions.net/'
  readonly sergeiUrl = 'http://kovacsek.selfip.net:18080/'
  uploadSaveUrl = this.apiUrl + "uploadattachment?project=" + environment.apiProjectName
  uploadRemoveUrl = this.apiUrl + "removeattachment"
  readonly QSPARAM_FILENAME = 'ctfilename'

  initData$ = new BehaviorSubject<AppData | null>(null)
  initDataLoading$ = new BehaviorSubject<boolean>(false)
  initDataError$ = new BehaviorSubject<DbCallError>(null)

  constructor(
    private http: HttpClient,
    private parser: ParserService,
  ) {
    this.arraysHttpResponseToDataTable = this.arraysHttpResponseToDataTable.bind(this)
    this.arraysToDataTable = this.arraysToDataTable.bind(this)
    this.concatBulkResultArraysToDataTabl = this.concatBulkResultArraysToDataTabl.bind(this)
    this.getAppData = this.getAppData.bind(this)
  }

  private callProc<T>(procname: string, params: ProcRequestParams, endPoint: string = 'callproc'): Observable<T> {
    // az environment változó a build során kerül beállításra az environment.ts vagy az environment.prod.ts fájl alapján
    const req: ProcRequest = {
      procname, params,
      project: environment.apiProjectName,
      impersonate: this.getImpersonate() || undefined,
    }
    const url = this.apiUrl + endPoint + '?' + procname
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
    return this.http.post<T>(url, req, { headers }).pipe(share());
  }

  private callBulkProc<T>(params: BulkProcRequestParams): Observable<T> {
    params['project'] = environment.apiProjectName
    if (this.getImpersonate()) params['impersonate'] = this.getImpersonate()!
    const url = `${this.apiUrl}bulkcallproc`
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
    return this.http.post<T>(url, params, { headers }).pipe(share());
  }

  sendMail(params: SendMailParams) {
    params['project'] = environment.apiProjectName
    const url = `${this.apiUrl}sendmail`
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
    return this.http.post<any>(url, params, { headers })
      .pipe(
        tap(this.throwOnError),
      )
  }

  private getQsImpersonate() {
    const impersonatedUser = sessionStorage.getItem(SESSION_STORAGE_IMPERSONATE)
    const qsImpersonate = impersonatedUser ? 'impersonate=' + impersonatedUser : undefined
    return qsImpersonate
  }
  
  private getImpersonate() {
    return sessionStorage.getItem(SESSION_STORAGE_IMPERSONATE)
  }

  ensureUser(email: string): Observable<AppUser | null> {
    return this.callProc<CTHttpResponse<AppUser>>('check_user', { _email: email })
      .pipe(
        tap(x => { console.log('ensure user response:', x) }),
        tap(this.throwOnError),
        map(x => x?.data || null))
  }

  listUsers(params: ListUsersParameters) {
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('list_users', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<AppUser>),
        map(this.parser.parseUsers),
        // map(this.flattenFlexFieldsArray),
      );
  }

  handleUsers(dbUser: HandleUsersParameters) {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('handle_users', dbUser)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<AppUser>),
        map(this.parser.parseUsers),
        map(this.getFirstItem<AppUser>),
      );
  }
  handleUserGroupMembers(params: HandleUserGroupMembersParams) {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('handle_usergroupmembers', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objRespToDataTable<DbGroupMembership>));
  }

  listProd(params: ListProdParameters) {
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('list_productions', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<Production>),
        // map(this.flattenFlexFieldsArray),
      );
  }

  handleProd(prodDefaults: HandleProdParameters) {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('handle_productions', prodDefaults)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<Production>));
  }

  listLocations() {
    return this.listPropItems(80)
      .pipe(
        map(this.parser.parseDbLocations),
      )
  }

  handleLocation(params: HandleShootingLocationParams) {
    const sqlParams: HandlePropItemsParams = this.parser.handleShootingLocationParams(params)
    return this.handlePropItems(sqlParams)
      .pipe(
        map(this.parser.parseDbLocations),
      )
  }

  listContracts(params: ListContractsParams): Observable<Contract[] | null> {
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('list_contracts_v6', params)
      .pipe(
        tap(this.throwOnError),
        map(this.arraysHttpResponseToDataTable<Contract>),
        map(this.parseNumericArrays<Contract>),// todo még nincs kész
      );
  }

  handleContracts(params: HandleContractsParams): Observable<Contract | null> {
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('handle_contracts', params)
      .pipe(
        tap(this.throwOnError),
        map(this.arraysHttpResponseToDataTable<Contract>),
        map(this.getFirstItem<Contract>),
        map(this.flattenFlexFields)
      )
  }

  listDepts(): Observable<Department[] | null | undefined> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('list_departments', {})
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<Department>),
        map(this.flattenFlexFields),
      )
  }
  handleDepts(params: HandleDeptsParams): Observable<Department | null> {
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('handle_departments', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<Department>),
        map(this.getFirstItem<Department>),
        map(this.flattenFlexFields),
      )
  }

  listCrew(): Observable<CrewListItem[]> {
    return this.callProc<CTHttpResponse<CrewListItem[]>>('list_users_basedata', { _prid: 1 } as ListCrewParams)
      .pipe(
        tap(this.throwOnError),
        map(this.getResponseData),
      );
  }


  listFlexTables$() {
    return of(this.listFlexTables())
  }
  listFlexTables() {
    return Object.keys(FlexTables)
      .filter(k => !isNaN(k as unknown as number))
      .map(k => ({ id: parseInt(k), value: FlexTables[k as any] }))
  }
  listFlexProps(): Observable<FlexProp[]> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('list_properties', {})
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<FlexProp>));
  }
  handleFlexProperties(params: HandleFlexPropertiesParams): Observable<FlexProp[]> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('handle_properties', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<FlexProp>));
  }
  listFlexEditTypes() {
    return of(
      Object.keys(FlexEditTypes)
        .filter(k => !isNaN(k as unknown as number))
        .map(k => ({ id: parseInt(k), value: FlexEditTypes[k as any] }))
    )
  }
  listPropItems(propid?: number): Observable<PropItem[]> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('list_propertyitems', { _propid: propid })
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<PropItem>));
  }
  handlePropItems(params: HandlePropItemsParams): Observable<PropItem[]> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('handle_propertyitems', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<PropItem>));
  }

  listTableProperties(): Observable<FlexTableProperty[]> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('list_tableproperties', {})
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<FlexTableProperty>));
  }

  handleTableProperties(params: HandleFlexTablePropertiesParams): Observable<FlexTableProperty[]> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('handle_tableproperties', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<FlexTableProperty>));
  }

  listTimesheets(params: ListTimesheetsParams): Observable<Timesheet[] | null> {
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('list_timesheets_v2', params)
      .pipe(
        tap(this.throwOnError),
        map(this.arraysHttpResponseToDataTable<Timesheet>));
  }

  handleTimesheets(params: HandleTimesheetsParams): Observable<Timesheet[] | null> {
    if (params._dayoptions !== undefined && !(params._dayoptions as Array<number>)?.length) params._dayoptions = {}
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('handle_timesheets', params)
      .pipe(
        tap(this.throwOnError),
        map(this.arraysHttpResponseToDataTable<Timesheet>));
  }

  handleMultipleTs(params: HandleTimesheetsParams[]): Observable<Timesheet[] | null> {
    const reqParams: BulkProcRequestParams = {}
    params.forEach((p, i) => {
      reqParams['handle_timesheets$' + i] = p
    })
    return this.callBulkProc<BulcProcTsResponse>(reqParams)
      .pipe(
        tap(this.throwOnError),
        map(this.concatBulkResultArraysToDataTabl<Timesheet>),
      )
  }

  checkTimesheet(params: CheckTimesheetParams): Observable<CheckTimesheetResponse> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('check_timesheet', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objRespToDataTable<CheckTimesheetResponse>),
      );
  }

  registerTsStatus(params: RegisterTsStatus): Observable<RegisterTsStatusResponse> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('register_timesheetstatus', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objRespToDataTable<RegisterTsStatusResponse>),
      );
  }

  listNotInvoicedTs(startDate: Moment, contractTypes?: string[]): Observable<ListNotInvoicedTs[]>{
    const reqParams: ListNotInvoicedTsParams = {
      _startdate: startDate.tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT),
      _contracttypes: contractTypes,
    }
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('list_notinvoicedtimesheets', reqParams)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable<ListNotInvoicedTs>),
    )
  }

  listItemHistory(params: ListItemHistoryParams): Observable<FieldHistoryParsed[] | null> {
    return this.callProc<CTHttpResponse<ProcResponseAsObjects>>('list_itemhistory', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objRespToDataTable<FieldHistory[]>),
        map(this.parser.parseHistory),
      )
  }

  getSalaryReasons(tsid: number): Observable<TsCalcInfo> {
    return this.callProc<CTHttpResponse<{}>>('calc_salary_details', { _tsid: tsid })
      .pipe(
        tap(this.throwOnError),
        map(this.objRespToDataTable<TsCalcInfo>));
  }

  getGrandOtta(categ?: GrandOttaCateg, startdate?: Moment, enddate?: Moment): Observable<GrandOtta> {
    const grandOtta:GrandOttaParams = {
      _prid: 1,
      _categ: categ !== undefined ? categ : GrandOttaCateg.All,
      _startdate: startdate?.tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT),
      _enddate: enddate?.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
    }
    return this.callProc<CTHttpResponse<GrandOttaResponse>>('report_ottabudget', grandOtta)
      .pipe(
        tap(this.throwOnError),
        map(this.objRespToDataTable<GrandOttaResponse>),
        map(parseGrandOtta),
      )
  }

  getCalculatedFeesForDay(params: CalcSalaryParams): Observable<CalculatedFeesForDay> {
    const reqParams = this.parser.calcSalaryParametersToSql(params)
    return this.callProc<CTHttpResponse<CalcSalaryResponseSql>>('list_calc_salary', reqParams)
      .pipe(
        tap(this.throwOnError),
        map(this.objRespToDataTable<CalcSalaryResponseSql>),
        map(this.parser.toCalcSalaryFromSql),
      )
  }

  ListDispo(params: {dayId?: number, startDate?: Moment | null, endDate?: Moment | null, propId?: number | null}): Observable<Dispo[]> {
    const dbParams: DispoParams = {
      _shid: params.dayId,
      _shstartdate: params.startDate?.tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT),
      _shenddate: params.endDate?.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _propid: params.propId, // 38 == currency exchange rate
    }
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('list_shootings', dbParams)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable),
        map(this.parser.parseDbDispos),
      )
  }

  handleDispo(params: HandleDispoParams): Observable<Dispo[]> {
    return this.callProc<CTHttpResponse<ProcResponseAsArrays>>('handle_shootings', params)
      .pipe(
        tap(this.throwOnError),
        map(this.objArrayRespToDataTable),
        map(this.parser.parseDbDispos),
      )
  }

  appDataRetryCount = 0
  getAppData(): void {
    const params = {
      list_tableproperties: '',
      list_departments: '',
      list_users: '',
      list_properties: '',
      list_productions: '',
      check_user: '',
      list_contracts_v6: '',
      list_propertyitems: '',
    }
    this.initDataLoading$.next(true)
    this.initDataError$.next(null)
    this.callBulkProc<AppData>(params)
      .subscribe({
        next: appData => {
          this.initDataLoading$.next(false)
          this.initDataError$.next(false)
          // Ha van auth0User, de nincs dbUser, akkor újra kell tölteni az appData-t, mert az első bejelentkezéskor jön létre a dbUser, és akkor még nem kap vissza semmit a getAppData.check_user és list_users
          if (!appData?.data.check_user?.usid && this.appDataRetryCount <= 5) {
            setTimeout(() => {
              this.getAppData()
            }, 1000 * this.appDataRetryCount++)
          }

          appData.data.list_contracts_v6 && (appData.data.list_contracts = this.arraysHttpResponseToDataTable({ data: appData.data.list_contracts_v6 }))
          if (!appData.data.list_users?.length) appData.data.list_users = []
          appData.data.check_user = this.parser.parseUser(appData.data.check_user) || null
          this.initData$.next(appData)
        },
        error: err => {
          this.initDataLoading$.next(false)
          console.error('App alapadatbetöltés hiba')
          this.initDataError$.next(err)
        }
      })

  }
  /**
   * 
   * @param documentType Meghívja a szerződést generáló endpointot
   * @param params szerződés azonosító
   * @param sendTo false==ne küldd ki; 'string'==a 'string'-re küldd az emailt; null==a szerződőnek küldd az emailt
   * @returns 
   */
  generateDoc(documentType: DocType, params: GenerateDocParams[], sendTo: string | false | null = null, isDraft: boolean, savePdf: boolean): Observable<PrintdocResponse | null> {
    const req: GenerateDocRequest = { 
      documentType, 
      documentList: params, 
      sendTo, 
      isDraft,
      savePdf,
      project: environment.apiProjectName,
     }
    const endPoint = 'printdoc'
    const qs = [documentType, this.getQsImpersonate()].filter(x => x).join('&')
    const url = this.apiUrl + endPoint + '?' + qs
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
    return this.http.post<PrintdocResponse>(url, req, { headers }).pipe(share());
  }

  removeDoc(fileId: string | null) {
    if (!fileId) throw 'A fájl id nem található'
    const req = { 
      fileId, 
      project: environment.apiProjectName 
    }
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
    return this.http.post<DocLink>(this.uploadRemoveUrl, req, { headers }).pipe(share())
  }

  /************************************************************************************/
  /*        CACHE
  /************************************************************************************/

  setCache(tableName: FlexTables, values: SqlDataRow[] | SqlDataRow): void {
    localStorage.setItem(FlexTables[tableName], JSON.stringify(values))
  }

  getCache<T>(tableName: FlexTables): T[] {
    const s = localStorage.getItem(FlexTables[tableName])
    if (!s) return []
    try {
      const retVal = JSON.parse(s)
      return retVal
    } catch (_) {
      return []
    }
  }

  clearCache(tableName?: FlexTables): void {
    const tables = tableName ? this.listFlexTables().map(t => t.value) : this.listFlexTables().filter(t => t.value == tableName).map(t => t.value)
    tables.forEach(t => {
      localStorage.removeItem(t)
    })
  }


  /************************************************************************************/
  /*        SEGÉD FÜGGVÉNYEK
  /************************************************************************************/

  arraysHttpResponseToDataTable<T extends SqlDataRow>(response: CTHttpResponse<ProcResponseAsArrays>): T[] | null {
    const arrays = response?.data || null
    return this.arraysToDataTable<T>(arrays)
  }

  arraysToDataTable<T extends SqlDataRow>(arrays: ProcResponseAsArrays | null): T[] | null {
    if (!arrays || arrays.length < 2) {
      console.error('Az SQL válasza nem szabályos formátumú. Kevesebb mint két tömbből áll');
      return null
    }
    if (!Object.keys(arrays)?.length) return [] // 0 sor esetén {}-t kapok
    const fields = arrays[0]
    const data = arrays[1]
    if (!data) {
      console.error('Az SQL válasza nem szabályos formátumú. A második tömb null');
      return null
    }
    //@ts-ignore 
    return data.map(row => Object.fromEntries(row.map((item: any, index: number) => ([
      [fields[index] as string], item
    ]))))
  }

  objArrayRespToDataTable<T extends SqlDataRow>(response: CTHttpResponse<ProcResponseAsObjects>): T[] {
    if (!response?.data?.length) return []
    return response.data as T[] || []
  }

  getResponseData(response: CTHttpResponse<ProcResponseAsObjects>): any {
    return response.data
  }

  objRespToDataTable<T extends SqlDataRow>(response: CTHttpResponse<ProcResponseAsObjects>): T {
    return response.data as unknown as T || {}
  }

  flattenFlexFields<T extends Contract | AppUser | Department>(response: T): T | null {
    if (!response?.details) return response
    const flexFields = response.details
      ?.reduce((prevProp: FlexPropWithValue | { [key: string]: any }, nextProp: FlexPropWithValue) => {
        const val = nextProp.effvalue === undefined ? nextProp.propvalue : nextProp.effvalue
        prevProp['f_' + nextProp.propcode] = val
        return prevProp
      }, {})
    //delete response.details
    return {
      ...response,
      ...flexFields,
    }
  }
  flattenFlexFieldsArray(response: SqlDataRow[] | null) {
    const retVal = response?.map(user => {
      const flexFields = user.details
        ?.reduce((prevProp: FlexPropWithValue | { [key: string]: any }, nextProp: FlexPropWithValue) => {
          const val = nextProp.effvalue === undefined ? nextProp.propvalue : nextProp.effvalue
          prevProp['f_' + nextProp.propcode] = val
          return prevProp
        }, {})
      //delete user.details
      return {
        ...user,
        ...flexFields,
      }
    })
    return retVal
  }
  getFirstItem<T>(array?: any[] | null): T {
    return array?.[0] || null
  }

  public throwOnError(response: CTHttpResponse<any>, doThrow: boolean = true): string | null {
    if (response.error?.source || response.error?.msg) {
      const msg = `${response.error.source} error: ${response.error.msg}`
      if (doThrow) throw msg
      return msg
    }
    return null
  }

  parseNumericArrays = this.parser.parseNumericArrays
  dbArrayToText = this.parser.dbArrayToText
  textToDbArray = this.parser.textToDbArray
  static textToDbArray = ParserService.textToDbArray

  static convertHumanNumberToNumber(humanNumber: string | number | null): number | null {
    if (humanNumber === null || humanNumber === undefined) return null
    if (typeof humanNumber === 'number') return humanNumber
    const retVal = humanNumber
      .toString().trim()
      .replace(/\s/g, '')
      .replace(/,/g, '.')
    return parseFloat(retVal)
  }

  private concatBulkResultArraysToDataTabl<T extends SqlDataRow>(responses: BulcProcTsResponse): T[] {
    const retVal: T[] = []
    if (!responses?.data) throw 'A válasz nem tartalmaz data mezőt'
    Object.values(responses.data).forEach(r => {
      if (!r) return
      const returnedTs = this.arraysToDataTable<T>(r)
      if (returnedTs) retVal.push(...returnedTs)
    })
    return retVal
  }

}

/************************************************************************************/
/*          INTERFACE-ek, CLASS-ok, TYPE-ok
/************************************************************************************/

export type ProcRequestParams = { [key: string]: string | number | number[] | {} | null | undefined }
export type BulkProcRequestParams = { [spName: string]: "" | {} }
export type ProcRequest = { 
  procname: string, 
  params: ProcRequestParams,
  project: string,
  impersonate?: string
 }
export type ProcResponseAsArrays = [[string], [any]]
export type ProcResponseAsObjects = { [key: string]: any }[]
export type SqlDataTable = SqlDataRow[]

export interface SqlDataRow {
  [key: string]: any

  details?: FlexPropWithValue[],
}
export type FileUploadResponse = {
  file_url?: string | null
  file_id?: string | null
  file_log?: string | null
  folder_id?: string | null
  folder_url?: string | null
}

export type PrintdocResponse = {
  backendMsg?: any[] // TODO mi lehet ez??

  data?: {
    docID: string | null,
    docLog: string | null,
    folderID: string | null,
  }[]
  /**
   * Draft esetén ebben kapom a számlaképet
   */
  html?: string | null

  error?: { source: string | null, msg: string | null }
  sqlMsg?: string | null
}

export class CTHttpResponse<T> {
  data?: T
  error?: CThttpErrorMsg
  sqlMsg?: string | string[] | null
  backendMsg?: string | string[] | null
}

export interface CThttpErrorMsg {
  source?: string | null
  msg?: string | null
}

export enum FlexTables {
  timesheets = 6,
  dispo = 5,
  contracts = 4,
  departments = 3,
  productions = 1,
  users = 7,
  tableproperties = 8,
  usergroupmembers = 9,

  properties = 1001,
  propitems = 1002,
}

export enum FlexEditTypes {
  combo = 1,
  int = 2,
  float = 3,
  text = 4,
  date = 5,
  upload = 6,
}

export enum EditLevels {
  devOnly = -1,
  crewMember = 1,
  prodMgr = 2,
  finance = 3,
}

export const vehiclenumber = "vehiclenumber"

export type GenerateDocRequest = {
  documentType: DocType
  documentList: GenerateDocParams[]
  sendTo?: string | null | false  //false==ne küldd ki; 'string'==a 'string'-re küldd az emailt; null==a szerződőnek küldd az emailt
  isDraft: boolean
  savePdf: boolean
  /**
   * A film címe vagy kódja vagy 'dev'. A data.service teszi bele
   */
  project?: string
}
export type DocType = 'contract' | 'invoice'
export type GenerateDocParams = {
  [key: string]: string | number | null | undefined
  docId?: string | null // ha null, akkor új dokumentumot generál, ha nem null, akkor a meglévőből készít pdf-et és küldi ki emailen
}

type AppData = {
  data: {
    list_tableproperties: FlexTableProperty[] | null,
    list_departments: Department[] | null,
    list_users: AppUser[] | null,
    list_properties: FlexProp[] | null,
    check_user: AppUser | null,
    list_productions: Production[] | null,
    list_contracts_v6: ProcResponseAsArrays | null,
    list_contracts: Contract[] | null,
    list_propertyitems: PropItem[] | null,  }
}

type BulcProcTsResponse = {
  data?: {
    [key: string]: ProcResponseAsArrays | null,
  }
}

