import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { MaterialModule } from './material.module';
import { UsersComponent } from './admin/users/users.component';
import { T7eModule } from './t7e/t7e.module';
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditContractComponent } from './contract/edit-contract/edit-contract.component';
import { SelectContractComponent } from './contract/select-contract/select-contract.component';
import { ErrorComponent } from './error/error.component';
import { SaveButtonComponent } from './util/save-button/save-button.component';
import { EditContractFieldsComponent } from './admin/edit-contract-fields/edit-contract-fields.component';
import { EditFlexFieldsFormComponent } from './admin/edit-contract-fields/edit-flex-fields-form/edit-flex-fields-form.component';
import { ManageContractsComponent } from './contract/manage-contracts/manage-contracts.component';
import { TimesheetsComponent } from './ts/timesheets/timesheets.component';
import { MyContractComponent } from './contract/my-contract/my-contract.component';
import { NotificationsComponent } from './util/notifications/notifications.component';
import { IconSuccessComponent } from './util/icon-success/icon-success.component';
import { CloseButtonComponent } from './util/close-button/close-button.component';
import { IconInfoComponent } from './util/icon-info/icon-info.component';
import { IconWarningComponent } from './util/icon-warning/icon-warning.component';
import { IconDangerComponent } from './util/icon-danger/icon-danger.component';
import { FlexFieldsComponent } from './util/flex-fields/flex-fields.component';
import { NewTimesheetComponent } from './ts/new-timesheet/new-timesheet.component';
import { EditTimesheetComponent } from './ts/edit-timesheet/edit-timesheet.component';
import { SaveCommentComponent } from './util/save-comment/save-comment.component';
import { GenInvoiceReportComponent } from './ts/gen-invoice-report/gen-invoice-report.component';
import { ManageTimesheetsComponent } from './ts/manage-timesheets/manage-timesheets.component';
import { FilterDateRangeComponent } from './util/filters/date-range/filter-date-range.component';
import { FilterContractComponent } from './util/filters/contract/filter-contract.component';
import { FilterUserComponent } from './util/filters/filter-user/filter-user.component';
import { FilterDeptComponent } from './util/filters/filter-dept/filter-dept.component';
import { EditableValueComponent } from './util/editable-value/editable-value.component';
import { HistoryIconComponent } from './util/history-icon/history-icon.component';
import { ProdDefaultsComponent } from './prod/prod-defaults/prod-defaults.component';
import { MindenComponent } from './util/minden/minden.component';
import { HistoryComponent } from './util/history/history.component';
import { KendoModule } from './kendo.module';
import { SalaryReasonsComponent } from './ts/salary-reasons/salary-reasons.component';
import { FilterTsstatusComponent } from './util/filters/filter-tsstatus/filter-tsstatus.component';
import { PopupMenuComponent } from './util/editable-value/popup-menu/popup-menu.component';
import { DaySelectorComponent } from './ts/day-selector/day-selector.component';
import { DoclinkComponent } from './util/doclink/doclink.component';
import { GenFileComponent } from './contract/gen-file/gen-contract-file.component';
import { GenTsFileComponent } from './ts/gen-ts-file/gen-ts-file.component';
import { DeptDefaultsComponent } from './dept/dept-defaults/dept-defaults.component';
import { CallbackPipe } from './util/pipes/callback.pipe';
import { EditPropFormComponent } from './admin/edit-contract-fields/edit-prop-form/edit-prop-form.component';
import { ManagePropertiesComponent } from './admin/edit-contract-fields/manage-properties/manage-properties.component';
import { EditPropitemsComponent } from './admin/edit-propitems/edit-propitems.component';
import { FlexFieldGroupComponent } from './util/flex-field-group/flex-field-group.component';
import { AdjacentTimesheetsComponent } from './ts/adjacent-timesheets/adjacent-timesheets.component';
import { ContractComponent } from './contract/contract/contract.component';
import { TsEditorReadonlyComponent } from './ts/edit-timesheet/ts-editor-readonly/ts-editor-readonly.component';
import { MyTimesheetsComponent } from './ts/my-timesheets/my-timesheets.component';
import { FilterContractTypeComponent } from './util/filters/filter-contract-type/filter-contract-type.component';
import { FilterConStatusComponent } from './util/filters/filter-con-status/filter-con-status.component';
import { SearchComponent } from './util/filters/search/search.component';
import { OtRateInputsComponent } from './util/ot-rate-inputs/ot-rate-inputs.component';
import { UserCardComponent } from './util/user-card/user-card.component';
import { PageWarningComponent } from './util/page-warning/page-warning.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { CrewListComponent } from './contract/crew-list/crew-list.component';
import { LangSelectorComponent } from './util/lang-selector/lang-selector.component';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import localeEN from '@angular/common/locales/en-GB';
import '@progress/kendo-angular-intl/locales/hu/all';
import { IntlModule, IntlService } from '@progress/kendo-angular-intl';
import { GetTsDatePipe } from './ts/ts.pipe';
import { OtRatePipe, ContractStatusNamePipe, ContractStatusClassPipe } from './contract/contract.pipe';
import { DistinctTsWeeklyErrors$Pipe } from './util/pipes/distinct-errors.pipe';
import { StatusComponent } from './util/status/status.component';
import { GrandOttaComponent } from './report/grand-otta/grand-otta.component';
import { WeeklyOttaComponent } from './report/weekly-otta/weekly-otta.component';
import { RefreshButtonComponent } from './util/refresh-button/refresh-button.component';
import { PageLoadingComponent } from './util/page-loading/page-loading.component';
import { IconRoleComponent } from './svg/icon-role/icon-role.component';
import { SectionTitleComponent } from './util/section-title/section-title.component';
import { PageWarningDescriptionDirective } from './util/page-warning/page-warning-description.directive';
import { OtherUsersDataBoxesComponent } from './dashboard/other-users-data-boxes/other-users-data-boxes.component';
import { DevComponent } from './util/dev/dev.component';
import { UserContractsComponent } from './contract/user-contract/user-contracts.component';
import { DistinctByPipe } from './util/pipes/distinct-by.pipe';
import { SelectUserComponent } from './admin/users/select-user/select-user.component';
import { MyProfileComponent } from './admin/profile/my-profile/my-profile.component';
import { EditProfileComponent } from './admin/profile/edit-profile/edit-profile.component';
import { DeptContractsComponent } from './contract/dept-contracts/dept-contracts.component';
import { AlertDialog, AlertSnackbar, ConfirmationDialog } from './util/notifications/notification.service';
import { LocaleService, FirstDayIntlService } from './locale.service';
import { LocaleProvider } from './locale.provider';
import { DeptTsComponent } from './ts/dept-ts/dept-ts.component';
import { TimesheetComponent } from './ts/timesheet/timesheet.component';
import { BasicUserDataComponent } from './dashboard/basic-user-data/basic-user-data.component';
import { RadioButtonComponent } from './util/radio-button/radio-button.component';
import { IconCompanyComponent } from './svg/icon-company/icon-company.component';
import { LocationsComponent } from './prod/locations/locations.component';
import { HolidaysComponent } from './prod/holidays/holidays.component';
import { FilterDateComponent } from './util/filters/filter-date/filter-date.component';
import { DaysComponent } from './prod/days/days.component';
import { OtTaBulkChangerComponent } from './contract/ot-ta-bulk-changer/ot-ta-bulk-changer.component';
import { DeletedTimesheetsComponent } from './ts/deleted-timesheets/deleted-timesheets.component';
import { FilterWorkhoursComponent } from './util/filters/filter-workhours/filter-workhours.component';


@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    HeaderComponent,
    ProfileComponent,
    EditContractComponent,
    SelectContractComponent,
    ErrorComponent,
    SaveButtonComponent,
    EditContractFieldsComponent,
    FlexFieldsComponent,
    EditFlexFieldsFormComponent,
    ManageContractsComponent,
    TimesheetsComponent,
    MyContractComponent,
    NotificationsComponent,
    IconSuccessComponent,
    CloseButtonComponent,
    IconInfoComponent,
    IconWarningComponent,
    IconDangerComponent,
    NewTimesheetComponent,
    EditTimesheetComponent,
    SaveCommentComponent,
    GenInvoiceReportComponent,
    ManageTimesheetsComponent,
    FilterDateRangeComponent,
    FilterContractComponent,
    FilterUserComponent,
    FilterDeptComponent,
    EditableValueComponent,
    HistoryIconComponent,
    ProdDefaultsComponent,
    MindenComponent,
    HistoryComponent,
    SalaryReasonsComponent,
    FilterTsstatusComponent,
    PopupMenuComponent,
    DaySelectorComponent,
    DoclinkComponent,
    GenFileComponent,
    GenTsFileComponent,
    DeptDefaultsComponent,
    CallbackPipe,
    EditPropFormComponent,
    ManagePropertiesComponent,
    EditPropitemsComponent,
    FlexFieldGroupComponent,
    AdjacentTimesheetsComponent,
    ContractComponent,
    TsEditorReadonlyComponent,
    MyTimesheetsComponent,
    FilterContractTypeComponent,
    FilterConStatusComponent,
    SearchComponent,
    OtRateInputsComponent,
    UserCardComponent,
    PageWarningComponent,
    DashboardComponent,
    CrewListComponent,
    LangSelectorComponent,
    GetTsDatePipe,
    OtRatePipe,
    ContractStatusNamePipe,
    ContractStatusClassPipe,
    DistinctTsWeeklyErrors$Pipe,
    StatusComponent,
    GrandOttaComponent,
    WeeklyOttaComponent,
    RefreshButtonComponent,
    PageLoadingComponent,
    IconRoleComponent,
    SectionTitleComponent,
    PageWarningDescriptionDirective,
    OtherUsersDataBoxesComponent,
    DevComponent,
    UserContractsComponent,
    DistinctByPipe,
    SelectUserComponent,
    MyProfileComponent,
    EditProfileComponent,
    DeptContractsComponent,
    ConfirmationDialog,
    AlertDialog,
    AlertSnackbar,
    DeptTsComponent,
    TimesheetComponent,
    BasicUserDataComponent,
    RadioButtonComponent,
    IconCompanyComponent,
    LocationsComponent,
    HolidaysComponent,
    FilterDateComponent,
    DaysComponent,
    OtTaBulkChangerComponent,
    DeletedTimesheetsComponent,
    FilterWorkhoursComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IntlModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      audience: environment.auth0.audience,
      scope: 'openid profile email read:all_data write:all_data access_as_user',
      httpInterceptor: {
        allowedList: [
          // Attach access tokens to any calls that start with '/api/'
          '/api/*',
          'https://europe-west1-crewtime-teszt.cloudfunctions.net/*',
        ]
      }
    }),
    MaterialModule,
    T7eModule,
    KendoModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    //{ provide: LOCALE_ID, useValue: 'hu' },
    LocaleProvider,
    {
      provide: IntlService,
      useClass: FirstDayIntlService,
    },
    GetTsDatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  constructor() {
    //registerLocaleData(localeEN, 'en-GB');
    registerLocaleData(localeHu, 'hu');
  }
}
