
<!-- <h2 t7e="page-title"></h2> -->

<ng-container *ngFor="let contract of contracts$ | async; let i=index; trackBy: trackByIndex">
    <ng-container *ngIf="contract; else unknownContract">
        <div class="department" *ngIf="isDeptDifferentFromPrevious(i)">
            {{ contract?.depname }}
        </div>
        <div class="contract-details">
            <div class="index">{{ i+1 }}.</div>
            <div class="username">
                {{ contract?.usname }}
            </div>
            <div class="position">
                {{ contract?.role }}
            </div>
            <div class="ot-rate">
                OT: {{ contract?.effectiveOtRates | otRate }}&nbsp;<span class="currency">{{ getCurrency(contract)}}</span>
            </div>
            <div class="ta-rate">
                TA: {{ contract?.f_tarate }}&nbsp;<span class="currency">{{ getCurrency(contract)}}</span>
            </div>
            <div class="save-feedback">
                <ng-container [ngSwitch]="contractSaveStatus.get(contract.conid!)">
                    <div *ngSwitchCase="'init'">
                    <!-- Még nem történt mentés -->
                    </div>
                    <div *ngSwitchCase="'saved'" class="save-successful">
                        &check; {{'save-successful' | t7e: 'Mentés OK'}}
                    </div>
                    <div *ngSwitchCase="'saving'" class="saving">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                    <div *ngSwitchDefault class="save-error">
                        <mat-icon>error</mat-icon>
                        {{ contractSaveStatus.get(contract.conid!) }}
                    </div>
                </ng-container>
                <!-- {{ contractSaveStatus.get(contract.conid!) }} -->
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #unknownContract>
    {{ 'unknown-contract' | t7e: 'Ismeretlen Start Form' }}
</ng-template>

<div class="currency-mismatch-error mt-2" *ngIf="isCurrencyMismatch$ | async">
    <mat-icon>warning</mat-icon>
    {{ 'currency-mismatch-error' | t7e: 'Különböző pénznemek!' }}
</div>

<form [formGroup]="otForm" (submit)="saveOt()" *ngIf="!(isCurrencyMismatch$ | async)" class="mt-2">
    <mat-form-field appearance="outline">
        <mat-label>OT</mat-label>
        <input matNativeControl #ot formControlName="ot">
        <span matSuffix>&nbsp;{{ currency }}</span>
        <mat-hint>{{ t7eOtRatesHint }}</mat-hint>
        <mat-error *ngIf="otForm?.hasError('required', 'ot')" t7e="required-field-error">
            Kötelező kitölteni
        </mat-error>
    </mat-form-field>

    <button mat-raised-button type="submit" color="primary" [disabled]="!otForm.valid || (saving$ | async)">
        <mat-spinner [diameter]="20" *ngIf="(saving$ | async); else saveIcon" class="spinner-inline-block"></mat-spinner>
        <ng-template #saveIcon>
            <mat-icon *ngIf="!(saving$ | async)">save</mat-icon>
        </ng-template>
        OT {{'save' | t7e : 'Mentés'}}
    </button>
</form>

<form [formGroup]="taForm" (submit)="saveTa()" *ngIf="!(isCurrencyMismatch$ | async)" class="mt-2">
    <mat-form-field appearance="outline">
        <mat-label>TA</mat-label>
        <input matNativeControl #ta formControlName="ta" type="number" [min]="0" [max]="100000" [step]="currency=='eur' ? 1 : 1000">
        <span matSuffix>&nbsp;{{ currency }}</span>
        <mat-error *ngIf="taForm?.hasError('pattern', 'ta')" t7e="invalid-number-error">
            Érvénytelen szám
        </mat-error>
        <mat-error *ngIf="taForm?.hasError('min', 'ta') || taForm?.hasError('max', 'ta')">
            {{ getMinMaxErrorMsg(ta) }}
        </mat-error>
        <mat-error *ngIf="taForm?.hasError('required', 'ta')" t7e="required-field-error">
            Kötelező kitölteni
        </mat-error>
    </mat-form-field>
    
    <button mat-raised-button type="submit" color="primary" [disabled]="!taForm.valid || (saving$ | async)">
        <mat-spinner [diameter]="20" *ngIf="(saving$ | async); else saveIcon" class="spinner-inline-block"></mat-spinner>
        <ng-template #saveIcon>
            <mat-icon *ngIf="!(saving$ | async)">save</mat-icon>
        </ng-template>
        TA {{'save' | t7e : 'Mentés'}}
    </button>
</form>