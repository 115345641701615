import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { BehaviorSubject, map, tap } from 'rxjs';
import { GrandOtta, GrandOttaPeriod, mapToGridDataResult } from '../../models/grand-otta';
import { CancelEvent, DetailExpandEvent, EditEvent, GridComponent, GridDataResult, RowArgs, SaveEvent } from '@progress/kendo-angular-grid';
import { AppService, DbCallError } from '../../app.service';
import { ProductionService } from '../../prod/production.service';
import { T7eService } from '../../t7e/t7e.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { NotificationService } from '../../util/notifications/notification.service';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { config } from '../../config';

@Component({
  selector: 'app-weekly-otta',
  templateUrl: './weekly-otta.component.html',
  styleUrls: ['./weekly-otta.component.scss']
})
export class WeeklyOttaComponent implements OnInit {
  multiCurrency = config.CONFIG_MULTIPLE_CURRENCIES
  readonly LOAD_MORE_WEEKS = 4
  readonly INIT_START_DATE = moment().subtract(this.LOAD_MORE_WEEKS, 'weeks').startOf('week')

  weeklyOttas$ = this.reportSvc.weeklyOtta$.pipe(
    // reverse order
    tap((weeklyOtta) => weeklyOtta?.periods?.reverse()),
    map(mapToGridDataResult)
  )
  isWeeklyOttasLoading$ = this.reportSvc.isWeeklyOttasLoading$
  weeklyOttasError$ = this.reportSvc.weeklyOttasError$

  dailyOttas$: BehaviorSubject<{period: string, grid: GridDataResult}>[] = []
  isDailyOttasLoading$: BehaviorSubject<boolean>[] = []
  dailyOttasError$: BehaviorSubject<DbCallError>[] = []

  public formGroup?: FormGroup = this.getFG()
  private editedRowIndex?: number
  saving$ = new BehaviorSubject<boolean>(false)

  debug = this.appSvc.debug
  isSaving$ = this.prodSvc.daySaving$

  startDate = this.INIT_START_DATE

  constructor(
    private reportSvc: ReportService,
    private appSvc: AppService,
    private prodSvc: ProductionService,
    private t7e: T7eService,
    private fb: FormBuilder,
    private notifSvc: NotificationService,
  ) { 
    this.appSvc.pageTitle$.next(this.t7ePageTitle)
    this.reportSvc.getWeeklyOttas(this.startDate, moment())
  }

  get currency () { return this.prodSvc.getCurrency() }

  ngOnInit(): void {
  }

  weekExpanded(e: DetailExpandEvent): void {
    const week = e.dataItem as GrandOttaPeriod
    const weekIndex = e.index
    if (!this.dailyOttas$[weekIndex]) {
      // Ha még nem léteznek, létrehozzuk őket
      if (!this.dailyOttas$[weekIndex]) {
        this.dailyOttas$[weekIndex] = new BehaviorSubject<{period: string, grid: GridDataResult}>({period: '', grid:{ data: [], total: 0 }})
      }
      if (!this.isDailyOttasLoading$[weekIndex]) {
        this.isDailyOttasLoading$[weekIndex] = new BehaviorSubject<boolean>(true)
      }
      if (!this.dailyOttasError$[weekIndex]) {
        this.dailyOttasError$[weekIndex] = new BehaviorSubject<DbCallError>(null)
      }
    }
    this.getDaily(week.period, weekIndex)
  }

   refresh(): void {
     this.reportSvc.getWeeklyOttas(this.startDate, moment())

    this.dailyOttas$.forEach((dailyOtta$, weekIndex) => {
      if (!dailyOtta$) return
      const period = dailyOtta$.value.period
      this.getDaily(period, weekIndex)
    })
   }
  
  loadMore(numWeeks: number = this.LOAD_MORE_WEEKS): void {
    const newStart = moment(this.startDate).subtract(numWeeks, 'weeks').startOf('week')
    this.reportSvc.getWeeklyOttas(newStart, this.startDate)
    this.startDate = newStart
  }

  private getDaily(period: string, weekIndex: number) {
    this.isDailyOttasLoading$[weekIndex].next(true)
    this.dailyOttasError$[weekIndex].next(null)
    
    this.reportSvc.getDailyOttasForWeek(moment(period))
    .pipe(
      map(mapToGridDataResult),
      tap(() => this.isDailyOttasLoading$[weekIndex].next(false)),
    )
    .subscribe({
      next: (data) => {
        data.data.sort((a, b) => b?.period?.localeCompare(a?.period))
        this.dailyOttas$[weekIndex].next({period: period, grid: data})
        this.dailyOttasError$[weekIndex].next(false)
      }, 
      error: (err) => {
        this.dailyOttasError$[weekIndex].next(err)
      }
    })
  }

  onEdit(args: EditEvent): void {
    const { dataItem } = args
    this.closeEditor(args.sender)
    this.formGroup = this.getFG( dataItem )
    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` built above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  onCancel(args: CancelEvent): void {
    this.closeEditor(args.sender, args.rowIndex);
  }

  onSave({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const extra = formGroup.value
    console.log('extra', extra)
    const dataItem = (sender.data as GridDataResult)?.data?.[rowIndex] as GrandOttaPeriod
    console.log('dataItem', dataItem)
    const startTime = moment(dataItem?.period)
    const endTime = startTime.clone().add(1, 'day')
    const dayId = dataItem?.extra?.shid || null
    console.log('day', startTime.format(), endTime.format(), dayId)
    const notif = this.notifSvc.addObservableNotif({msg: 'Mentés folyamatban...', class: 'info'})
    this.prodSvc.saveExtraOtta(dayId, extra, startTime, endTime)
      .subscribe({
        next: (result) => {
          console.log('saveExtraOtta result', result)
          this.notifSvc.modifyNotif(notif, {msg: 'Mentés kész', class: 'success', duration: 2000})
          this.refresh()
          this.closeEditor(sender, rowIndex);
        },
        error: (err) => {
          console.error('saveExtraOtta error', err)
          this.notifSvc.modifyNotif(notif, {msg: 'Hiba történt a mentés közben: ' + err, class: 'danger'})
        }
      })
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.formGroup = this.getFG();
  }

  private getFG(values?: ExtraOttaValues): FormGroup<ExtraOttaFG> {
    return this.fb.group({
      extra1: [values?.extra1 || null as number | null],
      extra2: [values?.extra2 || null as number | null],
      extra3: [values?.extra3 || null as number | null],
    })
  }

  get currencyFormat(): NumberFormatOptions {
    return {
      style: 'currency',
      currency: 'HUF',
      currencyDisplay: 'code',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      useGrouping: true,
    }
  } 

  /** T7E */
  t7ePageTitle = this.t7e.getTranslation('app-weekly-otta', 'page-title', 'innerText', null, null, 'Heti OT & TA')
  t7eColWeekTitle = this.t7e.getTranslation('app-weekly-otta', 'col.week', 'title', null, null, 'Hét')
  t7eColOttaTitle = this.t7e.getTranslation('app-weekly-otta', 'col.otta', 'title', null, null, 'OT + TA')
  t7eColDayTitle = this.t7e.getTranslation('app-weekly-otta', 'col.day', 'title', null, null, 'Nap')
  t7eColDailyOttaTitle = this.t7e.getTranslation('app-weekly-otta', 'col.daily-otta', 'title', null, null, 'Napi OT + TA')
}

export type ExtraOttaValues = {
  extra1: number | null,
  extra2: number | null,
  extra3: number | null,
}

type ExtraOttaFG = {
  extra1: FormControl<number | null>,
  extra2: FormControl<number | null>,
  extra3: FormControl<number | null>,
}