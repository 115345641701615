import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, firstValueFrom, map, Observable, startWith, Subject, take, takeUntil, timeout } from 'rxjs';
import { AppService, ButtonStatus, DbCallError } from '../../app.service';
import { DataService, FileUploadResponse, FlexEditTypes, FlexTables } from '../../data.service';
import { FlexFieldsService } from '../../util/flex-fields/flex-fields.service';
import { Contract, ContractParsed, ContractStatus } from '../../models/contract';
import { Department, DepartmentParsed } from '../../models/department';
import { HandleContractsParams } from '../../models/handle-contracts-params';
import { FlexTableProperty, Props } from '../../models/table-property';
import { UserService } from '../../user.service';
import { ContractService, QuestionAnswer, QuestionGroup } from '../contract.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { ProductionService } from '../../prod/production.service';
import { DeptService } from '../../dept/dept.service';
import { NumericValidator, OtRateValidator } from '../../util/validators/validators';
import * as moment from 'moment-timezone';
import { ParserService, SQL_DATE_FORMAT, SQL_TIME_FORMAT } from '../../parser.service';
import { AppUser } from '../../models/db-user';
import { T7eService } from '../../t7e/t7e.service';
import { config } from '../../config';
import { ErrorEvent } from '@progress/kendo-angular-upload';
import { TimesheetService } from '../../ts/timesheet.service';
import { StatusOf } from '../../util/status/status.component';

@Component({
  selector: 'app-edit-contract',
  templateUrl: './edit-contract.component.html',
  styleUrls: ['./edit-contract.component.scss']
})
export class EditContractComponent implements OnInit, OnDestroy {
  // Produkció specifikus konfig
  SHOW_GROWING_OT_RATE = config.SHOW_GROWING_OT_RATE
  ALLOW_CREW_MEMBER_EDIT_FEES = config.ALLOW_CREW_MEMBER_EDIT_FEES
  ALLOW_CREW_MEMBER_EDIT_OT_STEP = config.ALLOW_CREW_MEMBER_EDIT_OT_STEP
  ALLOW_CREW_MEMBER_EDIT_CONTRACT_DATES = config.ALLOW_CREW_MEMBER_EDIT_CONTRACT_DATES
  ALLLOW_CONTRACT_END_DATE = config.ALLLOW_CONTRACT_END_DATE
  MEAL_PENALTY = config.HU.TS.MEAL_PENALTY
  ALLOW_PERDIEM = config.ALLOW_PERDIEM
  ALLOW_CREW_EDIT_TA_FEE = config.ALLOW_CREW_MEMBER_EDIT_TA_FEE

  @Input() showContractStatus = true
  @Input() contract$ = new BehaviorSubject<ContractParsed | null>(null)
  @Input()
  set contract(value: ContractParsed | null) { this.contract$.next(value) }

  /** Ha dept-adminként hozok szerkesztek, kell tudni, melyik kolléga nevében */
  @Input() user$?: BehaviorSubject<AppUser | null>

  get debug() { return this.appSvc.debug }
  private _destroy$ = new Subject<void>();
  get contract() { return this.contract$.value }
  readonly saveError$ = this.contractSvc.saveError$
  readonly saveStatusError$ = this.contractSvc.saveStatusError$
  readonly savingContract$ = this.contractSvc.savingContract$
  readonly isFinanceApprovalNeeded$ = this.contractSvc.isFinanceApprovalNeededConfig$
  readonly loadingContract$ = new BehaviorSubject<boolean>(false)
  readonly loadContractError$ = new BehaviorSubject<DbCallError>(null)
  readonly ContractStatus = ContractStatus
  get contractStatus() { return this.contract$.value?.constatus || null }
  readonly departments$ = this.deptSvc.departments$
  readonly getEditLevels = this.appSvc.savingTableProperties$
  readonly isMoreThanDeptHead = this.userSvc.isMoreThanDeptHead
  readonly questionGroups$ = new BehaviorSubject<QuestionGroup[]>(this.contractSvc.getQuestionGroupPropCodes([]))
  readonly form: FormGroup
  validateFlexFields = new BehaviorSubject<boolean>(false)
  showSaveComment: { contract: ContractParsed, title: string, saveButtonText: string, isCommentOptional: boolean, newStatus: ContractStatus } | null = null
  isMealPenaltyInSf = this.MEAL_PENALTY === 'setAtProdDeptSf'

  public depts: Department[] | null = null
  // department default values based on the selected department, as BehaviorSubject
  readonly deptDefaults$ = new BehaviorSubject<DepartmentParsed | null>(null)
  get prodDefaults() { return this.prodSvc.prodDefaults }
  get prodDefaultOtRate() { return ((this.prodSvc.prodDefaults?.['otrate'])?.join(' / ')) || '' }
  get deptDefaultOtRate() {
    if (!this.deptDefaults$.value) return ''
    if (Object.keys(this.deptDefaults$.value).length == 0) return ''
    //@ts-ignore
    return (this.deptDefaults$.value.f_otrate || []).join(' / ') || ''
  }
  isPositionDuplicate$ = new BehaviorSubject<boolean>(false)


  get f() { return this.form.getRawValue() }
  get currency() { return this.f.f_saved_currency || this.f.f_currency}
  get flexFieldsFG() { return this.form.get('flexFields') as FormGroup }
  get flexFieldValues() { return this.flexFieldsFG.getRawValue() }
  get contractUser() { return this.userSvc.getUserById(this.contract$.value?.usid) }
  get isContractUserMyself() { return this.contractUser?.usid === this.userSvc.loggedinUser$.value?.usid }
  get contractStartDate() { return this.f.startdate }
  get contractEndDate() { return this.f.enddate }
  get isSent() { 
    const badStatuses = [
      ContractStatus.deleted,
      ContractStatus.disabled,
      ContractStatus.crewMember,
      null,
    ]
    return !badStatuses.includes(this.contractStatus)
  }
  get lang() { return this.t7e.lang }

  canEditForm: boolean = false
  showReadonlyFields: boolean = false
  tsExistsNotifHandler: number | null = null
  tsOtExistsNotifHandler: number | null = null
  tsTaExistsNotifHandler: number | null = null
  statusOf?: StatusOf
  
  get hasInvoiceContact() { return this.contractSvc.hasInvoiceContact(this.contract) }
  readonly contractFlexTable = FlexTables.contracts
  flexPropMap$ = this.appSvc.flexPropMap$
  isDev = this.userSvc.isDev
  isProdMgr = this.userSvc.isProdMgr
  isFinance = this.userSvc.isFinance
  isCrew = this.userSvc.isCrew
  isDeptHead = this.userSvc.isDeptHead
  isMoreThanCrew = this.userSvc.isMoreThanCrew
  get isAtCrewMember() { return !this.contract?.conid || this.contractStatus === ContractStatus.crewMember }
  get isAtDeptHead() { return this.contractStatus === ContractStatus.deptHead }
  get hasDeptHead() { return  this.contractSvc.hasDeptHead(this.contract) }
  readonly getContractStatusName = this.contractSvc.getStatusName
  readonly getContractStatusClass = (con?: ContractParsed | null) => { return this.contractSvc.getStatusClass(con) }
  prodDefaultCurrency = this.prodSvc.prodDefaults?.currency
  showOtCodes$ = this.prodSvc.prodDefaults$.pipe(map(d => !d?.codeOtta))
  showGasCode$ = this.prodSvc.prodDefaults$.pipe(map(d => !d?.codeGas))
  showParkingCode$ = this.prodSvc.prodDefaults$.pipe(map(d => !d?.codePark))
  showVignetteCode$ = this.prodSvc.prodDefaults$.pipe(map(d => !d?.codeVignette))

  filteredOptions = new Observable<Department[]>()

  history?: { tableid: FlexTables, id: number, propcode?: string, colname?: string}
  // flexFields = this.appSvc.tableProperties$
  //   .pipe(map(props => props
  //     ?.filter(p => p.tableid === FlexTables.contracts)
  //     ?.sort((a, b) => ((a.ord || 0) - (b.ord || 0)))
  //   ))

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private contractSvc: ContractService,
    private tsSvc: TimesheetService,
    private dataSvc: DataService,
    private appSvc: AppService,
    private userSvc: UserService,
    private flexFieldSvc: FlexFieldsService,
    private prodSvc: ProductionService,
    private deptSvc: DeptService,
    private notifSvc: NotificationService,
    private router: Router,
    private parser: ParserService,
    private t7e: T7eService,
  ) {
    this.markAsPristine = this.markAsPristine.bind(this)
    this.addFieldsToFG = this.addFieldsToFG.bind(this)

    this.appSvc.pageTitle$.next('Start Form')
    
    this.form = this.getDefaultFG()

    deptSvc.departments$
      .pipe(takeUntil(this._destroy$))
      .subscribe(d => {
      this.depts = d || []
      const depFC = this.form.get('department') as FormControl
      if (!depFC) return
      // this.filteredOptions = depFC
      //   .valueChanges.pipe(
      //     startWith(''),
      //     map(value => this.filterDepts(value))
      // )
      
      this.deptDefaults$.next(d?.find(d => d.depid === this.contract$.value?.depid) || null)
    })

    this.appSvc.tableProperties$
      .pipe(
        takeUntil(this._destroy$),
        filter(fields => !!fields?.length),
        map(fields => fields!.filter(f => f.tableid === FlexTables.contracts)),
      )
      .subscribe(this.addFieldsToFG)

    this.contract$
      .pipe(takeUntil(this._destroy$))
      .subscribe(c => {
        console.log('Selected contract2', c)
        this. statusOf = this.contract?.conid ? { tableId: FlexTables.contracts, id: this.contract?.conid } : undefined
        if (this.userSvc.isProdMgr || this.userSvc.isFinance) {
          this.canEditForm = true
          this.showReadonlyFields = true
        } else if (this.userSvc.isCrew && c?.constatus == ContractStatus.crewMember) {
          this.canEditForm = true
          this.showReadonlyFields = false
        } else if (this.userSvc.isCrew && c?.constatus == null) {
          this.canEditForm = true
          this.showReadonlyFields = false
        } else if (this.userSvc.isDeptHead && c?.constatus == ContractStatus.deptHead) {
          this.canEditForm = true
          this.showReadonlyFields = false
        } else {
          this.canEditForm = false
          this.showReadonlyFields = true
        }
        this.setFormValues(c)
      })

    contractSvc.saveError$
      .pipe(
        takeUntil(this._destroy$),
        filter(err => err === false)
      )
      .subscribe(this.markAsPristine)
    contractSvc.saveStatusError$
      .pipe(
        takeUntil(this._destroy$),
        filter(err => err === false)
      )
      .subscribe(this.markAsPristine)
    
    this.questionGroups$
      .pipe(
        takeUntil(this._destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(groups => {
      console.log('Question groups', groups)
    })

    this.form.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
      this.form.dirty && this.validateFlexFields.next(false)
      // const startTime = performance?.now()
      const formValues = this.flexFieldValues
      const qa: QuestionAnswer[] = Object.keys(formValues).map((x) => {
        const propcode = x.endsWith(flexFieldSvc.KENDOCONTROL_NAME) ? x.slice(0, -this.flexFieldSvc.KENDOCONTROL_NAME.length) : x
        const propId = appSvc.tableProperties?.find(p => p.propcode === propcode)?.propid
        if (!propId) throw 'Olyan mező van a form flex mezői között, ami nem létezik a tableProperties-ben: ' + propcode
        
        const answerPropItems = appSvc.aPropItems$[propId].value
        return {
          question: propcode,
          answer: answerPropItems?.find(api => api.picode == formValues[propcode])?.picode || '',
        }
      })
      // console.log('Flex field values', qa)
      // console.log(`Call to form.valueChanges took ${performance?.now() - startTime} milliseconds`)
      this.questionGroups$.next(this.contractSvc.getQuestionGroupPropCodes(qa))
      })
    
    combineLatest([this.form.valueChanges, this.contract$])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([formValues, contract]) => { 
        this.isPositionDuplicate$.next(this.isPositionDuplicate())
      })

    this.form.get('department')?.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
      this.deptDefaults$.next(val)
      })
  }
  
  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap
    const contractId = Number(routeParams.get('contractId')) || null
    if (!contractId) {
      return
    }

    this.loadingContract$.next(true)
    this.dataSvc.listContracts({ _conid: contractId })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: contracts => {
          this.loadingContract$.next(false)
          if (!contracts?.length) {
            this.loadContractError$.next(this.t7eMsgUnknownContract + contractId.toString())
            return
          }
          this.contract$.next(this.contractSvc.parseContract(contracts[0]))
          this.loadContractError$.next(false)
        },
        error: err => {
          this.loadingContract$.next(false)
          console.error(err);
          this.loadContractError$.next(this.t7eMsgLoadError + err.toString())
        }
      })

  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  private makeContractOfForm(): ContractParsed {
    const f = this.form.getRawValue()
    const deptId = this.getDeptId(f.department)
    let flexData = this.getFlexFields(f)
    const sStartdate = this.getStartDateString(f)
    const sEnddate = this.getEndDateString(f)
    const retVal: Contract = {
      conid: f.contractId,
      usid: f.usid,
      depid: deptId,
      role: f.position,
      code_salary: f.code_salary,
      code_otta: f.code_otta,
      code_gas: f.code_gas,
      code_park: f.code_park,
      code_vignette: f.code_vignette,
      code_perdiem: f.code_perdiem,
      code_other: f.code_other,
      doclink: f.doclink,
      contractfee: f.contractfee,
      startdate: sStartdate,
      enddate: sEnddate,
      dailyrate: f.salary,
      preprodrate: f.preprodrate,
      constatus: f.constatus,
      ...this.flexFieldSvc.prefixFlexFields(flexData),
    }
    return this.contractSvc.parseContract(retVal)
  }


  saveCommentReceived(comment: string): void {
    console.log(comment)
    this.saveStatusChange(ContractStatus.crewMember, comment)
    this.showSaveComment = null
  }

  sendBackToCrewClicked(): void {
    if (!this.contract) {
      this.notifSvc.addObservableNotif({ msg: 'Sikertelen mentés: ismeretlen start form', class: 'danger' })
      return
    }
    this.showSaveComment = {
      contract: this.contract,
      title: this.t7eNotifOkReturnToCrew,
      saveButtonText: this.t7eSaveCommentSendBackToCrewOkButton,
      isCommentOptional: true,
      newStatus: ContractStatus.crewMember,
    }
  }

  saveStatusChange(toStatus: ContractStatus, saveComment?: string): void {
    if ((this.contractStatus == ContractStatus.crewMember || !this.contractStatus || this.contractStatus == ContractStatus.deptHead)
      && (toStatus == ContractStatus.prodMgr || toStatus == ContractStatus.deptHead)) {
      let aMissingFields: string[] = []
      const contractUser = this.contractUser
      if (!contractUser) {
        this.notifSvc.addObservableNotif({
          msg: this.t7eMsgMissingUser,
          class: 'danger',
          duration: 0,
        })
        return
      }
      if (!this.contractSvc.hasAllRequiredUserFields(contractUser, this.makeContractOfForm(), aMissingFields)) {
        const aMissingFieldNames = aMissingFields.map(f => this.appSvc.tableProperties?.find(p => p.propcode === f)?.propname)
        this.notifSvc.addObservableNotif({
          msg: this.t7eMsgMissingProfileFields + aMissingFieldNames.join(', '),
          class: 'danger',
          duration: 0,
        })
        return
      }
      
      if (!this.contractSvc.hasAllRequiredContractFields(this.makeContractOfForm(), aMissingFields)) {
        const aMissingFieldNames = aMissingFields
          .map(f => this.appSvc.tableProperties?.find(p => p.propcode === f)?.propname || f)
          .filter(f => !!f)
        if (aMissingFields.includes('startdate')) aMissingFieldNames.push(this.t7eStartDateLabel)
        this.notifSvc.addObservableNotif({
          msg: this.t7eMsgMissingContractFields + aMissingFieldNames.join(', '),
          class: 'danger',
          duration: 0,
        })
        this.validateFlexFields.next(true)
        return
      }
    }
    // Kell emailt küldeni a státuszváltásról?
    const statusesToSendMail = [
      ContractStatus.crewMember,
      this.isFinanceApprovalNeeded$.value
        ? ContractStatus.financeApproved
        : ContractStatus.prodMgrApproved
    ]
    const oldStatus = (statusesToSendMail.includes(toStatus))
      ? this.contractStatus // csak akkor kell email, ha visszaküldjük a stábtagnak vagy prodMgrApproved-ra állítjuk
      : undefined
    this.onSubmit(toStatus, false, oldStatus || undefined, saveComment)
  }

  saveAsNew(): void {
    this.notifSvc.confirm({
      message: this.t7eMsgSaveAsNewConfirmDisableOldContract,
      title: this.t7eMsgSaveAsNewConfirmTitleDisableOldContract,
      okButtonText: this.t7eMsgSaveAsNewConfirmOkButtonTextDisableOldContract,
      noButtonText: this.t7eMsgSaveAsNewConfirmNoButtonTextDisableOldContract,
      cancelButtonText: this.t7eMsgSaveAsNewConfirmCancelButtonTextDisableOldContract,
      okCallback: () => {
        this.onSubmit(ContractStatus.disabled)
          ?.pipe(takeUntil(this._destroy$))
          .subscribe({
          next: () => {
              this.onSubmit(ContractStatus.crewMember, true)
                ?.pipe(takeUntil(this._destroy$))
                .subscribe({
              next: (c) => {
                c?.conid && this.contractSvc.selectContract(c.conid)
              }
            })
          },
          error: (err) => {
            console.error(err)
            this.onSubmit(ContractStatus.crewMember, true)
              ?.pipe(takeUntil(this._destroy$))
              .subscribe({
              next: (c) => {
                c?.conid && this.contractSvc.selectContract(c.conid)
              }
            })
          }
        }) 
      },
      noCallback: () => {
        this.onSubmit(ContractStatus.crewMember, true)
          ?.pipe(takeUntil(this._destroy$))
          .subscribe({
          next: (c) => {
            c?.conid && this.contractSvc.selectContract(c.conid)
          }
        })
      },
      cancelCallback: () => {},
    })
  }

  onSubmit(toStatus?: ContractStatus, saveAsNew: boolean = false, oldStatus?: ContractStatus, saveComment?: string) {
    const aErr: string[] = []
    // Klónozáskor nem baj, ha nem valid a form
    if (!saveAsNew && !this.isValid(aErr)) {
      this.notifSvc.addObservableNotif({ msg: aErr.join('; '), duration: 5000, class: 'danger' })
      return
    }
    const changeStatus = toStatus !== undefined
    const f = this.form.getRawValue()
    const deptId = this.getDeptId(f.department)
    let flexData = this.getFlexFields(f)
    if (flexData?.['invoice_contact'] === this.contractUser?.email) flexData['invoice_contact'] = null
    if (!saveAsNew && !this.confirmInvoiceContact(flexData)) return
    const sStartdate = this.getStartDateString(f)
    const sEnddate = this.getEndDateString(f)
    const usid = this.contractUser?.usid || this.user$?.value?.usid || this.userSvc.loggedinUser?.usid

    // Vizsgálandó esetek:
    // - ha semmiből semmi marad
    // - ha semmiből 0 vagy truthy lesz
    // - ha 0-ból vagy truthy-ból semmi lesz
    // - ha 0-ból vagy truthy-ból 0 vagy truthy lesz
    let newSalary = this.isFieldEmpty('salary') ? null : f.salary
    if (this.hasChanged('salary', 'dailyrate') && this.isFieldEmpty('salary')) newSalary = 0
    let newContractFee = this.isFieldEmpty('contractfee') ? null : f.contractfee
    if (this.hasChanged('contractfee') && this.isFieldEmpty('contractfee')) newContractFee = 0
    let newPreprodRate = this.isFieldEmpty('preprodrate') ? null : f.preprodrate
    if (this.hasChanged('preprodrate') && this.isFieldEmpty('preprodrate')) newPreprodRate = newSalary

    let params: HandleContractsParams = {
      // Ezeket majd törli a mentés előtt a handleContracts, ha nem kell
      _conid: saveAsNew ? null : f.contractId,
      _usid: usid,
      _depid: deptId,
      _role: f.position,
      _contracttype: !!f.flexFields.contract_type ? f.flexFields.contract_type.toString() : null,
      _code_salary: !!f.code_salary ? f.code_salary : '',
      _code_otta: !!f.code_otta ? f.code_otta : '',
      _code_gas: !!f.code_gas ? f.code_gas : '',
      _code_park: !!f.code_park ? f.code_park : '',
      _code_vignette: !!f.code_vignette ? f.code_vignette : '',
      _code_perdiem: !!f.code_perdiem ? f.code_perdiem : '',
      _code_other: !!f.code_other ? f.code_other : '',
      _doclink: !!f.doclink ? f.doclink : null,
      _contractfee: newContractFee,
      _startdate: sStartdate || null,
      _enddate: sEnddate || null,
      _data: Object.keys(flexData).length ? JSON.stringify(flexData) : null,
      _dailyrate: newSalary,
      _preprodrate: newPreprodRate,
      _sleepoverrate: !!f.sleepoverrate ? f.sleepoverrate : null,

      // majd törli a mentés előtt a handleContracts, ha nem kell
      _constatus: toStatus, 

      _savecomment: saveComment
    }
    const isFirstOwnContract = this.contractSvc.getContractsByUser(this.userSvc.loggedinUserId!).filter(c => !!c.constatus)?.length === 0
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.t7eMsgSaving, duration: false})
    const retVal = this.contractSvc.handleContracts(params, saveAsNew ? 'saveAsNew' : changeStatus, oldStatus)
      .pipe(takeUntil(this._destroy$))
    retVal.subscribe({
        next: response => {
          this.contract$.next(this.contractSvc.parseContract(response))
          if (isFirstOwnContract) {
            this.dataSvc.getAppData()
            this.dataSvc.initDataError$ 
              .pipe(
                takeUntil(this._destroy$),
                filter(x => x !== null), take(1))
              .subscribe(err => {
                if (err) {
                  this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eAppDataReloadFailed, class: 'danger', duration: 5000 })
                } else {
                  this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eMsgSaveSuccess, class: 'success', duration: 3000 })
                }
              })
          } else {
            this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eMsgSaveSuccess, class: 'success', duration: 3000})
          }
        },
        error: err => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eMsgSaveError, class: 'danger'})
          console.error(err)
        }
    })
    return retVal
  }

  private hasChanged(formFieldName: string, dbFieldName?: string): boolean {
    if (!dbFieldName) dbFieldName = formFieldName
    let formValue = this.form.get(formFieldName)?.getRawValue()
    if (typeof formValue === 'string') formValue = formValue.trim()
    const origValue = this.contract?.[dbFieldName]
    if (formValue === 0 && origValue !== 0) return true
    if (formValue !== 0 && origValue === 0) return true
    return formValue != origValue
  }

  private confirmInvoiceContact(flexFields: { [key: string]: any; }): boolean {
    if (!!flexFields?.['invoice_contact']) {
      return confirm(this.t7eMsgInvoiceContactConfirm)
    } else {
      return true
    }
  }
    
  isValid(aErr: string[]): boolean {
    if (!this.form.get('department')?.valid || !this.form.get('position')?.valid || !this.form.get('position')?.getRawValue()?.trim()) {
      if (this.t7e.lang == 'hu') aErr.push(`A részleg és pozíció megadása kötelező`)
      else aErr.push(`Department and positions must be filled out`)
    }
    if (this.isPositionDuplicate()) {
      if (this.t7e.lang == 'hu') aErr.push(`Ugyenez a pozíció már létezik másik start formban. Valahogy tedd egyedivé a pozíció megnevezését`)
      else aErr.push(`This position already exists for you. Please make the position name unique`)
    }
    let validations = [
      { fieldCode: 'taxnumber',         regex: /^\d{10}$/ },
      { fieldCode: 'companytaxnumber',  regex: /^\d{8}-\d-\d{2}$/ },
    ]
    validations.forEach((field) => {
      const fieldValue = this.flexFieldsFG.get(field.fieldCode)?.getRawValue()
      if (!fieldValue?.trim()) return // üres érték mindig menthető
      if (!(new RegExp(field.regex).test(fieldValue))) {
        const fieldTitle = this.flexFieldSvc.getBoxTitle(this.appSvc.flexProps$.value!, field.fieldCode)
        if (this.t7e.lang == 'hu') aErr.push(`Érvénytelen "${fieldTitle}": ${fieldValue}`)
        else aErr.push(`Invalid '${fieldTitle}': ${fieldValue}`)
      }
    })

    return aErr.length === 0
  }

  private getDeptId(department?: Department | string): number | null {
    if (!department) return null
    return typeof department === 'string'
    ? parseInt(department) || null
    : department.depid || null
  }

  private getFlexFields(formValues: any): { [key: string]: any } {
    let flexData = this.flexFieldSvc.getFlexFieldsWithValues(formValues)
    flexData = this.addSpecialFlexFields(flexData)
    return flexData
  }
    
  private getStartDateString(formValues: any): string | null {
    return !!formValues.startdate
    ? typeof formValues.startdate === 'string'
      ? formValues.startdate
        : this.parser.dateForSql(formValues.startdate)
    : null
  }

  private getEndDateString(formValues: any): string | null {
    if (!this.ALLLOW_CONTRACT_END_DATE) return this.parser.dateForSql(this.prodDefaults?.dShootingend) || null
    return !!formValues.enddate
    ? typeof formValues.enddate === 'string'
      ? formValues.enddate
        : this.parser.dateForSql(formValues.enddate)
    : null
  }

  addSpecialFlexFields(flexData: { [key: string]: any }) {
    const f = this.form.getRawValue()
    const aOtRates = this.dataSvc.textToDbArray(f.f_saved_otrate as string | null, true)
    const retVal = {
      ...flexData,
      preprodrate: f.preprodrate,
      otrate: aOtRates,
      sleepoverrate: f.f_saved_sleepoverrate,
      //workhours: f.f_saved_workhours,
      graceperiod: f.f_saved_graceperiod,
      overtimestep: f.f_saved_overtimestep,
      shortresthours: f.f_saved_shortresthours,
      longresthours: f.f_saved_longresthours,
      tarate: f.f_saved_tarate,
      mealPenaltyRate: f.f_saved_mealPenaltyRate,
      currency: f.f_saved_currency,
      dailyfeewithletters: f.f_dailyfeewithletters,
      dailyRateEng: f.f_dailyRateEng,
      preprodRateWithLetters: f.f_preprodRateWithLetters,
      preprodRateEng: f.f_preprodRateEng,
      dailybasedrentalrate1: f.f_dailybasedrentalrate1,
      dailybasedrentalrate2: f.f_dailybasedrentalrate2,
      dailybasedrentalrate3: f.f_dailybasedrentalrate3,
      dailybasedrentalrate4: f.f_dailybasedrentalrate4,
      dailybasedrentalrate5: f.f_dailybasedrentalrate5,
      dailybasedrentalrate6: f.f_dailybasedrentalrate6,
      monthlybasedrentalrate1: f.f_monthlybasedrentalrate1,
      monthlybasedrentalrate2: f.f_monthlybasedrentalrate2,
      monthlybasedrentalrate3: f.f_monthlybasedrentalrate3,
      dailybasedrentalrate1code: f.f_dailybasedrentalrate1code,
      dailybasedrentalrate2code: f.f_dailybasedrentalrate2code,
      dailybasedrentalrate3code: f.f_dailybasedrentalrate3code,
      dailybasedrentalrate4code: f.f_dailybasedrentalrate4code,
      dailybasedrentalrate5code: f.f_dailybasedrentalrate5code,
      dailybasedrentalrate6code: f.f_dailybasedrentalrate6code,
      monthlybasedrentalrate1code: f.f_monthlybasedrentalrate1code,
      monthlybasedrentalrate2code: f.f_monthlybasedrentalrate2code,
      monthlybasedrentalrate3code: f.f_monthlybasedrentalrate3code,
      dailybasedrentalrate1name: f.f_dailybasedrentalrate1name,
      dailybasedrentalrate2name: f.f_dailybasedrentalrate2name,
      dailybasedrentalrate3name: f.f_dailybasedrentalrate3name,
      dailybasedrentalrate4name: f.f_dailybasedrentalrate4name,
      dailybasedrentalrate5name: f.f_dailybasedrentalrate5name,
      dailybasedrentalrate6name: f.f_dailybasedrentalrate6name,
      monthlybasedrentalrate1name: f.f_monthlybasedrentalrate1name,
      monthlybasedrentalrate2name: f.f_monthlybasedrentalrate2name,
      monthlybasedrentalrate3name: f.f_monthlybasedrentalrate3name,
      dailybasedrental1type: f.f_dailybasedrental1type ? 1 : 0,
      dailybasedrental2type: f.f_dailybasedrental2type ? 1 : 0,
      dailybasedrental3type: f.f_dailybasedrental3type ? 1 : 0,
      dailybasedrental4type: f.f_dailybasedrental4type ? 1 : 0,
      dailybasedrental5type: f.f_dailybasedrental5type ? 1 : 0,
      dailybasedrental6type: f.f_dailybasedrental6type ? 1 : 0,
      monthlybasedrental1type: f.f_monthlybasedrental1type ? 1 : 0,
      monthlybasedrental2type: f.f_monthlybasedrental2type ? 1 : 0,
      monthlybasedrental3type: f.f_monthlybasedrental3type ? 1 : 0,

      folderid: f.f_folderid,
    }
    return retVal
  }

  markAsPristine(): void {
    this.form.markAsPristine()
  }

  // private filterDepts(value: string | Department): Department[] {
  //   const filterValue = typeof value === 'string'
  //     ? this.appSvc.normalizeString(value)
  //     : this.appSvc.normalizeString(value?.depname)
  //   return this.depts
  //     ?.filter(dep => this.appSvc.normalizeString(dep.depname).includes(filterValue))
  //     || this.depts
  //     || []
  // }

  private addFieldsToFG(fields: FlexTableProperty[] | null): void {
    const userFlexFields = fields?.filter(f => !this.form.get('f_' + f.propcode))
    this.flexFieldSvc.addFieldsToFG(this.form, userFlexFields)
  }

  canSave(): ButtonStatus {
    const con = this.makeContractOfForm()
    if (this.debug) return {disabled: false, title: this.t7eBtnTitleDebug}
    if (this.savingContract$.value) return { disabled: true, title: this.t7eBtnTitleSaving }
    if (!this.form.dirty) return { disabled: true, title: this.t7eBtnTitleNoChange }
    if (this.isFinance || this.isProdMgr) return { disabled: false, title: this.t7eBtnTitleSave }
    if (this.isDeptHead) {
      if (this.contractSvc.isAtProdMgr(con) || this.contractSvc.isApprovedOrAfter(con)) return { disabled: true, title: this.t7eNotEditableWarning }
      else return { disabled: false, title: this.t7eBtnTitleSave }        
    } 
    if ((!this.userSvc.isMoreThanCrew || this.userSvc.isDeptAdmin)) {
      if (this.contractSvc.isAtCrew(con)) return { disabled: false, title: this.t7eBtnTitleSave }
      else return { disabled: true, title: this.t7eNotEditableWarning }
    }
    // allow to save incomplete form; 
    if (!this.form.valid) return { disabled: false, title: this.t7eBtnTitleInvalid }
    //else return { disabled: false, title: this.t7eBtnTitleSave }
    return { disabled: true, title: this.t7eBtnTitleNoPermission }
  }

  canSaveAsNew(): ButtonStatus {
    if (this.debug) return { disabled: false, title: this.t7eBtnTitleDebug }
    if (this.savingContract$.value) return { disabled: true, title: this.t7eBtnTitleSaving }
    if (!this.form.get('department')?.getRawValue()?.depid 
      || !this.form.get('position')?.getRawValue()?.trim()) {
      return { disabled: true, title: this.t7eEmptyContractCannotSaveAsNew }
    }
    return { disabled: false, title: this.t7eBtnTitleSaveAsNew }

  }

  canChangeStatus(toStatus: ContractStatus): ButtonStatus {
    if (this.debug) return {disabled: false, title: this.t7eBtnTitleDebug}
    if (this.form.dirty) return { disabled: true, title: this.t7eBtnTitleSaveFirst }
    const c = this.contract$.value
    if (!c) return { disabled: true, title: this.t7eBtnTitleUnknownContract }
    switch (toStatus) {
      case ContractStatus.disabled:
        return this.contractSvc.canDisable(c)
      case +ContractStatus.deleted: // + nélkül hibát dob a compiler
        return this.contractSvc.canDelete(c)
      case ContractStatus.crewMember:
        return this.contractSvc.canSendBackToCrew(c)
      case ContractStatus.deptHead:
        return this.contractSvc.canSetAtDeptHead(c)
      case ContractStatus.prodMgr:
        return this.contractSvc.canSetAtProdMgr(c)
      case ContractStatus.prodMgrApproved:
        return this.contractSvc.canProdMgrApprove(c)
      case ContractStatus.financeApproved:
        return this.contractSvc.canFinanceApprove(c)
      case ContractStatus.readyToSign:
        return this.contractSvc.canSetReadyToSign(c)
      case ContractStatus.signed:
        return this.contractSvc.canSetSigned(c)
      default:
        return { disabled: true, title: this.t7eBtnTitleUnknownStatus }
      }
  }

  get btnTextSendToProdMgr(): string {
    if (this.isSent) return this.t7eBtnTextSendToProdMgrReturn
    else return this.t7eBtnTextSendToProdMgr
  }
  
  getFcMinMax(element: HTMLInputElement): {min: number, max: number} {
    return {
        min: parseFloat(element.min),
        max: parseFloat(element.max),
    }
  }
  getMinMaxErrorMsg(element: HTMLInputElement): string {
    const minMax = this.getFcMinMax(element)
    if (!minMax.min && minMax.min !== 0) return `Maximum: ${minMax.max}`
    if (!minMax.max && minMax.max !== 0) return `Minimum: ${minMax.min}`
    return `Minimum: ${minMax.min}, maximum: ${minMax.max}`
  }
  get feesOption() {
    return [{
      value: null, 
      disabled: !this.isProdMgr,
      
    }]
  }

  private getDefaultFG(): FormGroup {
    return this.fb.group({
      contractId: [null],
      usid: [null],
      department: ['', [Validators.required]],
      position: ['', [Validators.required]],
      code_salary: [''],
      code_otta: [''],
      code_gas: [''],
      code_park: [''],
      code_vignette: [''],
      code_perdiem: [''],
      code_other: [''],
      doclink: [''],
      contractfee: [''],
      startdate: [''],
      enddate: [''],
      salary: ['', [NumericValidator]],
      preprodrate: ['', [NumericValidator]],
      constatus: [''],

      // flex fields
      f_dailyfeewithletters: [''],
      f_dailyRateEng: [''],
      f_preprodRateWithLetters: [''],
      f_preprodRateEng: [''],

      f_graceperiod: [this.feesOption, [NumericValidator]],
      f_sleepoverrate: [this.feesOption, [NumericValidator]],
      f_overtimestep: [this.feesOption, [NumericValidator]],
      f_shortresthours: [this.feesOption, [NumericValidator]],
      f_longresthours: [this.feesOption, [NumericValidator]],
      f_tarate: ['', [NumericValidator]],
      f_currency: [''],

      f_saved_graceperiod: [this.feesOption, [Validators.min, NumericValidator]],
      f_saved_otrate: ['', [OtRateValidator]],
      f_saved_sleepoverrate: [this.feesOption, [NumericValidator]],
      f_saved_overtimestep: [this.feesOption, [Validators.min, Validators.max, NumericValidator]],
      f_saved_shortresthours: [this.feesOption, [NumericValidator]],
      f_saved_longresthours: [this.feesOption, [NumericValidator]],
      f_saved_tarate: [this.feesOption, [NumericValidator]],
      f_saved_mealPenaltyRate: [this.feesOption, [NumericValidator]],
      f_saved_currency: [''],
    
      f_dailybasedrentalrate1: [null, [Validators.min, Validators.max, NumericValidator, ]],
      f_dailybasedrentalrate2: [null, [Validators.min, Validators.max, NumericValidator, ]],
      f_dailybasedrentalrate3: [null, [Validators.min, Validators.max, NumericValidator, ]],
      f_dailybasedrentalrate4: [null, [Validators.min, Validators.max, NumericValidator,]],
      f_dailybasedrentalrate5: [null, [Validators.min, Validators.max, NumericValidator,]],
      f_dailybasedrentalrate6: [null, [Validators.min, Validators.max, NumericValidator,]],
      f_monthlybasedrentalrate1: [null, [Validators.min, Validators.max, NumericValidator, ]],
      f_monthlybasedrentalrate2: [null, [Validators.min, Validators.max, NumericValidator, ]],
      f_monthlybasedrentalrate3: [null, [Validators.min, Validators.max, NumericValidator, ]],
      f_dailybasedrentalrate1code: [null, ],
      f_dailybasedrentalrate2code: [null, ],
      f_dailybasedrentalrate3code: [null, ],
      f_dailybasedrentalrate4code: [null,],
      f_dailybasedrentalrate5code: [null,],
      f_dailybasedrentalrate6code: [null,],
      f_monthlybasedrentalrate1code: [null, ],
      f_monthlybasedrentalrate2code: [null, ],
      f_monthlybasedrentalrate3code: [null, ],
      f_dailybasedrentalrate1name: [null, ],
      f_dailybasedrentalrate2name: [null, ],
      f_dailybasedrentalrate3name: [null, ],
      f_dailybasedrentalrate4name: [null,],
      f_dailybasedrentalrate5name: [null,],
      f_dailybasedrentalrate6name: [null,],
      f_monthlybasedrentalrate1name: [null, ],
      f_monthlybasedrentalrate2name: [null, ],
      f_monthlybasedrentalrate3name: [null,],
      f_dailybasedrental1type: [null,],
      f_dailybasedrental2type: [null,],
      f_dailybasedrental3type: [null,],
      f_dailybasedrental4type: [null,],
      f_dailybasedrental5type: [null,],
      f_dailybasedrental6type: [null,],
      f_monthlybasedrental1type: [null,],
      f_monthlybasedrental2type: [null,],
      f_monthlybasedrental3type: [null,],
      
      f_folderid: [null, ],

      flexFields: this.fb.group({})
    })
  }

  private setFormValues(contract: ContractParsed | null): void {
    let defaultForm = {
      contractId: contract?.conid || null,
      usid: contract?.usid || null,
      department: this.deptSvc.departments$.value?.find(d => d.depid == contract?.depid),
      position: contract?.role || null,
      code_salary: contract?.code_salary,
      code_otta: contract?.code_otta,
      code_gas: contract?.code_gas,
      code_park: contract?.code_park,
      code_vignette: contract?.code_vignette,
      code_perdiem: contract?.code_perdiem,
      code_other: contract?.code_other,
      doclink: contract?.doclink,
      contractfee: contract?.contractfee,
      startdate: contract?.startdate,
      enddate: contract?.enddate,
      salary: contract?.dailyrate, 
      preprodrate: contract?.preprodrate,
      //otrate: contract?.otrate, 
      constatus: contract?.constatus,

      // flex fields
      f_dailyfeewithletters: contract?.['f_dailyfeewithletters'],
      f_dailyRateEng: contract?.['f_dailyRateEng'],
      f_preprodRateWithLetters: contract?.['f_preprodRateWithLetters'],
      f_preprodRateEng: contract?.['f_preprodRateEng'],

      f_graceperiod: contract?.['f_graceperiod'],
      //f_otrate: this.dataSvc.dbArrayToText(contract?.['f_otrate']),
      f_overtimestep: contract?.['f_overtimestep'],
      f_sleepoverrate: contract?.['f_sleepoverrate'],
      // f_workhours: contract?.['f_workhours'],
      f_shortresthours: contract?.['f_shortresthours'],
      f_longresthours: contract?.['f_longresthours'],
      f_norestcompensation: contract?.['f_tarate'],
      f_currency: contract?.['f_currency'],

      f_saved_graceperiod: contract?.['f_saved_graceperiod'],
      f_saved_otrate: this.dataSvc.dbArrayToText(contract?.['f_saved_otrate']),
      f_saved_overtimestep: contract?.['f_saved_overtimestep'],
      f_saved_sleepoverrate: contract?.['f_saved_sleepoverrate'],
      // f_saved_workhours: contract?.['f_saved_workhours'],
      f_saved_shortresthours: contract?.['f_saved_shortresthours'],
      f_saved_longresthours: contract?.['f_saved_longresthours'],
      f_saved_tarate: contract?.['f_saved_tarate'],
      f_saved_mealPenaltyRate: contract?.['f_saved_mealPenaltyRate'],
      f_saved_currency: contract?.['f_saved_currency'],

      f_dailybasedrentalrate1: contract?.['f_dailybasedrentalrate1'],
      f_dailybasedrentalrate2: contract?.['f_dailybasedrentalrate2'],
      f_dailybasedrentalrate3: contract?.['f_dailybasedrentalrate3'],
      f_dailybasedrentalrate4: contract?.['f_dailybasedrentalrate4'],
      f_dailybasedrentalrate5: contract?.['f_dailybasedrentalrate5'],
      f_dailybasedrentalrate6: contract?.['f_dailybasedrentalrate6'],
      f_monthlybasedrentalrate1: contract?.['f_monthlybasedrentalrate1'],
      f_monthlybasedrentalrate2: contract?.['f_monthlybasedrentalrate2'],
      f_monthlybasedrentalrate3: contract?.['f_monthlybasedrentalrate3'],
      f_dailybasedrentalrate1code: contract?.['f_dailybasedrentalrate1code'],
      f_dailybasedrentalrate2code: contract?.['f_dailybasedrentalrate2code'],
      f_dailybasedrentalrate3code: contract?.['f_dailybasedrentalrate3code'],
      f_dailybasedrentalrate4code: contract?.['f_dailybasedrentalrate4code'],
      f_dailybasedrentalrate5code: contract?.['f_dailybasedrentalrate5code'],
      f_dailybasedrentalrate6code: contract?.['f_dailybasedrentalrate6code'],
      f_monthlybasedrentalrate1code: contract?.['f_monthlybasedrentalrate1code'],
      f_monthlybasedrentalrate2code: contract?.['f_monthlybasedrentalrate2code'],
      f_monthlybasedrentalrate3code: contract?.['f_monthlybasedrentalrate3code'],
      f_dailybasedrentalrate1name: contract?.['f_dailybasedrentalrate1name'],
      f_dailybasedrentalrate2name: contract?.['f_dailybasedrentalrate2name'],
      f_dailybasedrentalrate3name: contract?.['f_dailybasedrentalrate3name'],
      f_dailybasedrentalrate4name: contract?.['f_dailybasedrentalrate4name'],
      f_dailybasedrentalrate5name: contract?.['f_dailybasedrentalrate5name'],
      f_dailybasedrentalrate6name: contract?.['f_dailybasedrentalrate6name'],
      f_monthlybasedrentalrate1name: contract?.['f_monthlybasedrentalrate1name'],
      f_monthlybasedrentalrate2name: contract?.['f_monthlybasedrentalrate2name'],
      f_monthlybasedrentalrate3name: contract?.['f_monthlybasedrentalrate3name'],
      f_dailybasedrental1type: contract?.['f_dailybasedrental1type'] as number | null == 1 ? true :  false,
      f_dailybasedrental2type: contract?.['f_dailybasedrental2type'] as number | null == 1 ? true :  false,
      f_dailybasedrental3type: contract?.['f_dailybasedrental3type'] as number | null == 1 ? true :  false,
      f_dailybasedrental4type: contract?.['f_dailybasedrental4type'] as number | null == 1 ? true :  false,
      f_dailybasedrental5type: contract?.['f_dailybasedrental5type'] as number | null == 1 ? true :  false,
      f_dailybasedrental6type: contract?.['f_dailybasedrental6type'] as number | null == 1 ? true :  false,
      f_monthlybasedrental1type: contract?.['f_monthlybasedrental1type'] as number | null == 1 ? true :  false,
      f_monthlybasedrental2type: contract?.['f_monthlybasedrental2type'] as number | null == 1 ? true :  false,
      f_monthlybasedrental3type: contract?.['f_monthlybasedrental3type'] as number | null == 1 ? true :  false,

      f_folderid: contract?.['f_folderid'],
    }
    this.form.patchValue(defaultForm)
    const contrProps = this.appSvc.tableProperties?.filter(p => p.tableid == FlexTables.contracts) || []
    contract && this.flexFieldSvc.setFlexValues(this.flexFieldsFG, contrProps, contract)
    //this.flexFieldsFG.get('contract_type')?.setValue(contract?.contracttype)


    // const flexFieldsFG = this.form.get('flexFields') as FormGroup
    // this.appSvc.tableProperties?.forEach(field => {
    //   const contractFieldName = 'f_' + field.propcode
    //   flexFieldsFG.get(field.propcode)?.setValue(contract[contractFieldName])
    // })

    if (!this.canEditForm) this.form.disable()
    else if (!contract?.conid) this.form.enable()
    else {
      // get the number of ts for this contract
      // if there are no ts, then enable the form, else disable the following fields:
      // - currency
      // - otrate
      // - tarate
      // - grace period
      this.tsSvc.listTs({ _conid: contract.conid })
        .pipe(take(1))
        .subscribe({
          next: ts => {
            this.form.enable()
            if (ts?.length) {
              !this.isDev && this.form.get('f_saved_currency')?.disable({ emitEvent: false })
              !this.isDev && this.form.get('f_saved_graceperiod')?.disable({ emitEvent: false })
              if (this.tsExistsNotifHandler === null) this.tsExistsNotifHandler = this.notifSvc.addObservableNotif({ msg: this.t7eMsgTsExists, class: 'info', duration: 10000 })
              const otHours = ts?.reduce((acc, cur) => acc + (cur?.calc_othours || 0), 0)
              const taHours = ts?.reduce((acc, cur) => acc + (cur?.calc_tahours || 0), 0)
              otHours && (this.tsOtExistsNotifHandler === null) && (this.tsOtExistsNotifHandler = this.notifSvc.addObservableNotif({ msg: this.t7eMsgTsOtExists, class: 'info', duration: 10000 }))
              taHours && (this.tsTaExistsNotifHandler === null) && (this.tsTaExistsNotifHandler = this.notifSvc.addObservableNotif({ msg: this.t7eMsgTsTaExists, class: 'info', duration: 10000 }))
            }
          },
          error: err => {
            console.error(err)
            this.notifSvc.addObservableNotif({ msg: this.t7eMsgTsExistsError, class: 'danger', duration: 10000 })
            this.form.disable()
          }
        })

    }
  }

  get uploadFolderName(): string {
    return this.contractSvc.getUploadFolderName(this.makeContractOfForm())
  }

  get uploadFolderId(): string | undefined {
    return this.form.get('f_folderid')?.getRawValue()
  }

  onFileUploadSuccess(uploadResponse: FileUploadResponse): void {
    const newFolderId = uploadResponse.folder_id
    const oldFolderId = this.form.get('f_folderid')?.value
    if (newFolderId === oldFolderId) return
    this.form.get('f_folderid')?.setValue(newFolderId)
  }

  onFileUploadError(err: ErrorEvent): void {
    this.notifSvc.addObservableNotif({msg: this.t7eMsgFileUploadError, class: 'danger', duration: 5000})
  }

  get userCantEdit() {
    if (this.appSvc.debug) return false
    switch (this.contractStatus) {
      case ContractStatus.disabled:
      case +ContractStatus.deleted: // + nélkül hibát dob a compiler
        return true
      case ContractStatus.crewMember:
        if (this.userSvc.isDeptAdmin) return false
        return !this.contractSvc.isOwnContract(this.contract$.value)
      case ContractStatus.prodMgr:
        return !this.userSvc.isProdMgr
      case ContractStatus.prodMgrApproved:
      case ContractStatus.readyToSign:
      case ContractStatus.signed:
        return !this.userSvc.isProdMgr || !this.userSvc.isFinance
      default:
        return false
    }
  }

  readonly formFieldStyles = {
    //height: '5rem',
    display: 'inline-block',
    'max-width': '500px',
  }
  readonly formGroupStyles = {
    'width': '100%',
    'display': 'flex',
    'flex-direction': 'column',
    'align-items': 'center',
  }

  isFieldEmpty(fieldName: string): boolean {
    console.assert(this.form.get(fieldName), 'Ismertetlen mezőnév: ' + fieldName)
    const formVal = this.form.get(fieldName)?.getRawValue()
    return formVal === null || formVal === undefined || !formVal.toString().trim()
  }

  getDeptName(department: Department): string {
    return department && department.depname || ''
  }

  showHistory(e: MouseEvent, propcodeOrColname: string, isFlex: boolean) {
    const conid = this.contract$.value?.conid
    console.log('Előzmények a start formhoz: ', conid);
    if (!conid) return
    this.history =
    {
      tableid: FlexTables.contracts,
      id: conid,
      propcode: isFlex ? propcodeOrColname : undefined,
      colname: isFlex ? undefined : propcodeOrColname,
    }
  }

  getFormErrors() {
    return [
      ...Object.keys(this.form.controls)
        .map(key => ({
          control: key,
          error: this.form.controls[key].errors
        }))
        .filter(c => !!c.error),
      ...Object.keys(this.flexFieldsFG.controls)
        .map(key => ({
          control: key,
          error: this.flexFieldsFG.controls[key].errors
        }))
        .filter(c => !!c.error),
    ]
  }

  isPositionDuplicate(): boolean {
    const usid = this.contractUser?.usid || this.user$?.value?.usid || this.userSvc.loggedinUser?.usid
    console.assert(usid, 'unknown user:', usid)
    if (!usid) return false
    const position = this.form.get('position')?.getRawValue()?.trim().toLowerCase()
    if (!position) return false
    return !!this.contractSvc.getContractsByUser(usid)
      .filter(c => c.conid !== this.contract?.conid
        && c.constatus! > 0
        && c.role?.trim().toLowerCase() === position)
      .length
  }

  navToNewTs(): void {
    if (this.route.component?.name === 'DeptContractsComponent') this.router.navigate(['/dept/ts'])
    else this.router.navigate(['/my/timesheets'])
  }

  /** T7E */
  t7eSectionDeptAndRole= this.t7e.getTranslation('app-edit-contract', 'section-dept-and-role', 'title', 'Részleg és munkakör') 
  t7eSectionOt= this.t7e.getTranslation('app-edit-contract', 'section-ot', 'title', 'Túlóra (OT)') 
  t7eSectionTa= this.t7e.getTranslation('app-edit-contract', 'section-ta', 'title', 'Pihenőidő (TA)') 
  t7eSectionRentalsMonthly = this.t7e.getTranslation('app-edit-contract', 'section-rentals-monthly', 'title', null, null, 'Havi bérleti díjak')
  t7eSectionRentalsDaily = this.t7e.getTranslation('app-edit-contract', 'section-rentals-daily', 'title', null, null, 'Napi bérleti díjak')
  t7eSectionCodes= this.t7e.getTranslation('app-edit-contract', 'section-fees', 'title', 'Könyvelési kódok') 
  t7eSectionStartDate = this.ALLLOW_CONTRACT_END_DATE 
    ? this.t7e.getTranslation('app-edit-contract', 'section-start-end', 'title', 'Kezdete és vége') 
    : this.t7e.getTranslation('app-edit-contract', 'section-start', 'title', 'Kezdete')
  t7eStartDateLabel = this.t7e.getTranslation('app-edit-contract', 'startdate-label', 'innerText', 'Kezdete') 
  t7eSectionOther= this.t7e.getTranslation('app-edit-contract', 'section-other', 'title', 'Egyebek') 

  t7eNoCrewWarning= this.t7e.getTranslation('app-edit-contract', 'no-crew-warning', 'title', 'A gyártásvezető még nem engedélyezte a start form kitöltést')
  t7eNotEditableWarning= this.t7e.getTranslation('app-edit-contract', 'not-editable-warning', 'title', 'A Start Formot beküldted, ezért az adataidat már nem szerkesztheted. Ha módosítani kívánsz, lépj kapcsolatba a gyártásvezetővel')
  t7eSavingContract= this.t7e.getTranslation('app-edit-contract', 'saving-contract', 'innerText', 'Start form mentése folyamatban...') 
  t7eFees= this.t7e.getTranslation('app-edit-contract', 'fees', 'title', 'Díjak') 
  t7eWillBeEffectiveTitle = this.t7e.getTranslation('app-edit-contract', 'will-be-effective-when-saved', 'title', null, null, 'Jóváhagyáskor ez az érték kerül jóváhagyásra')
  // Gomb szövegek
  t7eSaveError= this.t7e.getTranslation('app-edit-contract', 'save-error', 'innerText', 'A mentés nem sikerült') 
  t7eSendError= this.t7e.getTranslation('app-edit-contract', 'send-error', 'innerText', 'Nem sikerült beküldeni!') 
  t7eSendBackToCrew= this.t7e.getTranslation('app-edit-contract', 'send-back-to-crew', 'title', 'Visszaküldés a stábtagnak') 
  t7eSendBackError= this.t7e.getTranslation('app-edit-contract', 'send-back-error', 'innerText', 'Nem sikerült visszaküldeni!') 
  t7eSendToDeptHead = this.t7e.getTranslation('app-edit-contract', 'btn-send-to-dept-head', 'innerText', 'Beküldés a részlegvezetőnek')
  t7eDeptApprove = this.t7e.getTranslation('app-edit-contract', 'btn-dept-approve', 'innerText', 'Jóváhagyás')
  t7eApprove = `${this.t7e.getTranslation('app-edit-contract', 'btn-approve', 'title', 'Jóváhagyás')} 
    ${this.isFinance ? this.t7e.getTranslation('pipe', 'btn-approve-as-prodmgr', 'innerText', null, null, ' (Gyártásvezető)') : ''}`
  t7eApproveError= this.t7e.getTranslation('app-edit-contract', 'approve-error', 'innerText', 'Nem sikerült jóváhagyni!') 
  t7eDeleteError = this.t7e.getTranslation('app-edit-contract', 'delete-error', 'innerText', 'Nem sikerült törölni!')
  t7eFinanceApprove = this.t7e.getTranslation('app-edit-contract', 'btn-finance-approve', 'title', 'Pénzügyes jóváhagyás') 
  t7eFinanceApproveError = this.t7e.getTranslation('app-edit-contract', 'finance-approve-error', 'innerText', 'Nem sikerült jóváhagyni!') 
  t7eReadyToSign= this.t7e.getTranslation('app-edit-contract', 'btn-ready-to-sign', 'title', 'Aláírásra kész') 
  t7eSigned= this.t7e.getTranslation('app-edit-contract', 'btn-signed', 'title', 'Aláírva') 
  btnTextSaveAsNew = this.t7e.getTranslation('app-edit-contract', 'btn-save-as-new', 'innerText', null, null, 'Mentés új start formként')
  t7eBtnTitleSaveAsNew = this.t7e.getTranslation('app-edit-contract', 'btn-save-as-new', 'title', 'Mentés új start formként')

  // Btn title és text
  t7eBtnTitleDebug= this.t7e.getTranslation('app-edit-contract', 'can-save-debug', 'title', 'DEBUG bekapcsolva') 
  t7eBtnTitleSaving= this.t7e.getTranslation('app-edit-contract', 'can-save-saving', 'title', 'Mentés folyamatban...') 
  t7eBtnTitleNoChange= this.t7e.getTranslation('app-edit-contract', 'can-save-no-change', 'title', 'Nincs változtatás az űrlapon') 
  t7eBtnTitleInvalid= this.t7e.getTranslation('app-edit-contract', 'can-save-invalid', 'title', 'Kitöltési hibák') 
  t7eBtnTitleNoPermission = this.t7e.getTranslation('app-edit-contract', 'can-save-no-permission', 'title', 'Nincs jogosultságod a mentéshez')
  t7eBtnTitleSave = this.t7e.getTranslation('app-edit-contract', 'can-save-save', 'title', 'Mentés')
  t7eBtnTitleSaveFirst= this.t7e.getTranslation('app-edit-contract', 'can-change-status-save-first', 'title', 'Előbb mentsd el a változtatásokat') 
  t7eBtnTitleUnknownContract= this.t7e.getTranslation('app-edit-contract', 'can-change-status-unknown-contract', 'title', 'Ismeretlen start form') 
  t7eBtnTitleUnknownStatus= this.t7e.getTranslation('app-edit-contract', 'can-change-status-unknown-status', 'title', 'Ismeretlen státusz') 
  t7eBtnTextSendToProdMgrReturn= this.t7e.getTranslation('app-edit-contract', 'btn-send-to-prodmgr-return', 'innerText', 'Visszaküldés gyártásvezetőnek') 
  t7eBtnTextSendToProdMgr= this.t7e.getTranslation('app-edit-contract', 'btn-send-to-prodmgr', 'innerText', 'Beküldés a gyártásvezetőnek') 
  
  // Notif msg
  readonly compName = 'app-edit-contract'
  t7eMsgUnknownContract= this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-unknown-contract', 'Ismeretlen start form id: ') 
  t7eMsgLoadError= this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-load-error', 'Hiba a startform betöltésekor: ') 
  t7eMsgMissingUser= this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-missing-user', 'Nem létezik a start formot létrehozó felhasználó') 
  t7eMsgMissingProfileFields = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-missing-profile-fields', 'Hiányos profil adatok! A start form beküldéséhez előbb töltsd ki a PROFILodban következő mezőket: ') 
  t7eMsgMissingContractFields= this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-missing-contract-fields', 'A start form beküldéséhez előbb töltsd ki a következő mezőket: ') 
  t7eMsgSaving= this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-saving', 'Adatok mentése folyamatban...') 
  t7eMsgSaveSuccess= this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-save-success', 'Az adatok mentése sikerült') 
  t7eMsgSaveError= this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-save-error', 'Az adatok mentése sikertelen') 
  t7eMsgInvoiceContactConfirm = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-invoice-contact-confirm', null, null, 'Ha megadsz számlázási kontaktot, nem láthatod a timesheetk pénzügyi adatait. Biztos ezt akarod?')
  t7eAppDataReloadFailed = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-app-data-reload-failed', null, null, 'Az adatok mentése sikerült, de hiba történt az új adatok lekérése közben. Kérlek, frissítsd az oldalt!')
  t7eMsgFileUploadError = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-file-upload-error', null, null, 'Hiba történt a fájl feltöltése közben')
  t7eMsgTsExists = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-ts-exists', null, null, 'A start formhoz már létezik timesheet. A pénznem nem szerkeszthető')
  t7eMsgTsOtExists = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-ts-ot-exists', null, null, 'A start formhoz már létezik timesheet TÚLÓRÁVAL. Ha módosítod a túlóradíjat, módosul a timesheet összege')
  t7eMsgTsTaExists = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-ts-ta-exists', null, null, 'A start formhoz már létezik timesheet PIHENŐIDŐVEL. Ha módosítod a pihenőidő díját, módosul a timesheet összege')
  t7eMsgTsExistsError = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-ts-exists-error', null, null, 'Nem sikerült megállapítani, hogy a start formhoz létezik-e timesheet. A start form szerkesztése nem lehetséges. Kérlek, töltsd újra az oldalt!')
  t7eMsgSaveAsNewConfirmDisableOldContract = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-save-as-new-confirm-disable-old-contract', null, null, 'Szeretnéd a régi start formot letiltani, hogy ne lehessen rá több timesheetet bevinni?')
  t7eMsgSaveAsNewConfirmTitleDisableOldContract = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-save-as-new-confirm-title-disable-old-contract', null, null, 'Régi start form letiltása')
  t7eMsgSaveAsNewConfirmOkButtonTextDisableOldContract = this.t7eMsgSaveAsNewConfirmTitleDisableOldContract
  t7eMsgSaveAsNewConfirmNoButtonTextDisableOldContract = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-save-as-new-confirm-no-button-text-disable-old-contract', null, null, 'A régi start form nem lesz letiltva')
  t7eMsgSaveAsNewConfirmCancelButtonTextDisableOldContract = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-save-as-new-confirm-cancel-button-text-disable-old-contract', null, null, 'Mégsem')
  t7eEmptyContractCannotSaveAsNew = this.t7e.getTranslation('app-edit-contract', 'notif-msg', 'msg-empty-contract-cannot-save-as-new', null, null, 'Klónozás előtt a részleg és pozíció megadása kötelező')
  t7eNotifOkReturnToCrew = this.t7e.getTranslation('app-manage-contracts', 'notif', 'ok-return-to-crew', null, null, 'Kiválasztott start formok visszaküldése a stábtagnak')
  t7eSaveCommentSendBackToCrewOkButton = this.t7e.getTranslation('app-manage-contracts', 'save-comment', 'send-back-to-crew-ok-button', null, null, 'Visszaküldöm')

  /** T7E VÉGE */

  test() {
    console.log(this.form.getRawValue());
  }
}
