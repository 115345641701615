import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { AppService, ButtonStatus, DbCallError } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { DocService, GenInvRepGroupBy } from '../../doc.service';
import { ContractParsed, ContractStatus } from '../../models/contract';
import { HandleTimesheetsParams, ListTimesheetsParams, PrevPeriodInvoices, RegisterTsStatus, Timesheet, TimesheetParsed, TimesheetStatus, TimesheetWeekly, YesNoPartial } from '../../models/timesheet';
import { UserService } from '../../user.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { TimesheetService } from '../timesheet.service';
import { RemoveFileClickedEvent } from '../../util/doclink/doclink.component';
import { ProductionService } from '../../prod/production.service';
import { DataService } from '../../data.service';
import { StateService, getReorderedConfig, getResizedConfig } from '../../state.service';
import { ColumnReorderEvent, ColumnResizeArgs, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment-timezone';
import { SQL_TIMEZONE, SQL_DATE_FORMAT, SQL_DATETIME_FORMAT, SQL_MONTH_DAY_FORMAT, UI_DATE_MMM_D_FORMAT } from '../../parser.service';
import { T7eService } from '../../t7e/t7e.service';
import { PropItem } from '../../models/table-property';
import { Moment } from 'moment-timezone';

@Component({
  selector: 'app-gen-invoice-report',
  templateUrl: './gen-invoice-report.component.html',
  styleUrls: ['./gen-invoice-report.component.scss']
})
export class GenInvoiceReportComponent implements OnInit,OnDestroy {
  @ViewChild('grid') grid!: GridComponent

  private _destroy$ = new Subject<void>();
  get debug() { return this.appSvc.debug }
  form: FormGroup
  isDataLoaded = false

  isGenerating: number[] = [] // azon a conid-k listája, aminek a generálása elkezdődött
  isRemovingDoc: string[] = [] // azon a fileid-k listája, aminek a Google Drive törlése elkezdődött
  generatingError$: { [conid: number]: BehaviorSubject<DbCallError> } = {} // azon a conid-k listája, aminek a generálása hibára futott
  removingDocError$: { [fileid: string]: BehaviorSubject<DbCallError> } = {} // azon a fileid-k listája, amineka Google Drive törlése hibára futott
  isJustGenerated: string[] = [] // azon a conid-k listája, aminek a generálása most történt
  draft$ = new BehaviorSubject<string | null>(null)

  tsDistinct$ = new BehaviorSubject<TimesheetWeekly[]>([])
  statusSentFilter$ = new BehaviorSubject(false)
  statusInvoiceAcceptedFilter$ = new BehaviorSubject(false)
  contractTypeFilter$ = new BehaviorSubject<string | null>(null)
  gridData$ = combineLatest([this.statusSentFilter$, this.statusInvoiceAcceptedFilter$, this.contractTypeFilter$, this.tsDistinct$])
    .pipe(
      map(([statusSentFilter, statusInvoiceAcceptedFilter, contractTypeFilter, tsDistinct]) => {
        if (!statusSentFilter && !statusInvoiceAcceptedFilter && !contractTypeFilter) return tsDistinct
        return tsDistinct.filter(ts =>
          (!statusSentFilter || ts.isSent !== 'yes')
          && (!statusInvoiceAcceptedFilter || ts.isInvAccepted !== 'yes')
          && (!contractTypeFilter || contractTypeFilter == this.contractSvc.getContractById(ts.conid)?.contractTypeAsPropItem?.picode)
        )
      })
    )
  tsAll$ = new BehaviorSubject<TimesheetParsed[]>([])
  aTsList$ForConid: BehaviorSubject<Timesheet[]>[] = []
  aTsList$ForUsid: BehaviorSubject<Timesheet[]>[] = []
  tsListLoading$ = new BehaviorSubject<boolean>(false)
  generationButtonStatus$ = new BehaviorSubject<ButtonStatus>({ disabled: true, title: 'Nincs leszűrve' })

  constructor(
    private tsSvc: TimesheetService,
    private contractSvc: ContractService,
    private userSvc: UserService,
    private dataSvc: DataService,
    private appSvc: AppService,
    private prodSvc: ProductionService,
    private docSvc: DocService,
    private notifSvc: NotificationService,
    private fb: FormBuilder,
    private stateSvc: StateService,
    private t7e: T7eService,
  ) {
    this.appSvc.pageTitle$.next('Számlamelléklet generálás')
    this.reload = this.reload.bind(this)

    this.form = this.getFG()
    this.form.valueChanges.pipe(
      takeUntil(this._destroy$),
      map(f => f.hideSent),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    ).subscribe(this.statusSentFilter$)

    this.form.valueChanges.pipe(
      takeUntil(this._destroy$),
      map(f => f.hideInvoiceAccepted),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    ).subscribe(this.statusInvoiceAcceptedFilter$)

    this.form.valueChanges.pipe(
      takeUntil(this._destroy$),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map(form => (form.contractType as PropItem)?.picode || null),
    ).subscribe(this.contractTypeFilter$)

    this.form.valueChanges.pipe(
      takeUntil(this._destroy$),
      map(form => ({ from: form.from as Date | null, to: form.to as Date | null })),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    ).subscribe(() => this.generationButtonStatus$.next({ disabled: true, title: 'Generálás előtt frissítsd a táblázatot!'}))

    this.tsDistinct$.next(this.stateSvc.genInvReportPageState$?.value?.grid?.data || [])

    this.tsSvc.tsList$
      .pipe(
        filter(list => !!list?.length),
        takeUntil(this._destroy$),
      )
      .subscribe(x => this.reload())

    type ATimesheetParsed = TimesheetParsed[]
    this.tsAll$.pipe(takeUntil(this._destroy$))
    .subscribe({
      next: tsList => {
        const tsListForConid: ATimesheetParsed[] = []
        const tsListForUsid: ATimesheetParsed[] = []
        tsList
          .filter(ts => ts?.tsstatus! > 0)
          .forEach(ts => {
            if (!tsListForConid[ts.conid!]) tsListForConid[ts.conid!] = []
            if (!tsListForUsid[ts.usid!]) tsListForUsid[ts.usid!] = []
            tsListForConid[ts.conid!].push(ts)
            tsListForUsid[ts.usid!].push(ts)
          })
        for (let conid = 0; conid < tsListForConid.length; conid++) {
          if (!conid) continue
          if (!this.aTsList$ForConid[conid]) this.aTsList$ForConid[conid] = new BehaviorSubject<Timesheet[]>(tsListForConid[conid])
          else this.aTsList$ForConid[conid].next(tsListForConid[conid])
        }
        for (let usid = 0; usid < tsListForUsid.length; usid++) {
          if (!usid) continue
          if (!this.aTsList$ForUsid[usid]) this.aTsList$ForUsid[usid] = new BehaviorSubject<Timesheet[]>(tsListForUsid[usid])
          else this.aTsList$ForUsid[usid].next(tsListForUsid[usid])
        }

        this.handleLoadedTsList(tsList)
      }
    })

    // Lekérem az összes userre az előző időszak azámláit
    this.tsDistinct$
      .pipe(
        takeUntil(this._destroy$),
    ).subscribe(tsDistinct => {
      if (!tsDistinct.length) return
      tsSvc.listNotInvoicedTs(moment(this.fromFC.getRawValue()), undefined, tsDistinct.map(ts => ts.usid!))
    })
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(f => {
        this.stateSvc.setGenInvReportPageState({ filters: f })
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  get fromFC() { return this.form.get('from') as FormControl }
  get toFC() { return this.form.get('to') as FormControl }
  get contractTypeFC() { return this.form.get('contractType') as FormControl }
  get contractFC() { return this.form.get('contract') as FormControl }
  get groupBy() { return this.form.value.groupBy as GenInvRepGroupBy }
  get isGroupByRole() { return this.groupBy === 'role' }
  get isGroupByUser() { return this.groupBy === 'user' }
  get minDate() { return this.prodSvc.prodDefaults$.value?.dPreparationstartday || new Date(2000, 0, 1) }
  get maxDate() { return new Date() }
  getDepartmentsByUser = this.contractSvc.getDepartmentNamesByUser
  getRolesByUser = this.contractSvc.getRolesByUser
  getCompaniesByUser = this.contractSvc.getCompaniesByUser
  isProdMgr = this.userSvc.isProdMgr
  isFinance = this.userSvc.isFinance
  isPrevPeriodInvoiced$ = this.tsSvc.isPrevPeriodInvoiced$

  draftGenerated(html: string | null): void {
    this.draft$.next(html)
  }
  closeDraft(e?: Event): void {
    e?.preventDefault()
    this.draft$.next(null)
  }
  
  reload({ usid }: { usid?: number } = {}): void {
    //console.log(this.form.value)
    this.contractSvc.listContracts({ }) // a last invoice date a contractból jön
    
    const params = this.getSPParams({usid})
    if (!params._startdate || !params._enddate) return

    this.tsListLoading$.next(true)
    this.dataSvc.listTimesheets(params)
      .pipe(
        takeUntil(this._destroy$),
        map(data => data
          ?.map(this.tsSvc.parseTs)
          ?.sort((a, b) => (b.tsid || -1) - (a.tsid || 1))
          || []
        ),
      )
      .subscribe({
        next: tsList => {
          this.tsListLoading$.next(false)
          this.tsAll$.next(tsList || [])
          this.generationButtonStatus$.next({ disabled: false, title: ''})
        },
        error: err => {
          this.tsListLoading$.next(false)
          this.notifSvc.addObservableNotif({ msg: 'Hiba történt a Timesheet lista betöltése közben', class: 'danger' })
        }
      })
  }

  private handleLoadedTsList(tsList?: TimesheetParsed[] | null) {
    if (!tsList) return
    const f = this.form.getRawValue()
    if (!f.from || !f.to) return
    const startDate = new Date(f.from),
      endDate = this.appSvc.getEndOfDay(f.to),
      contractType = f.contractType as PropItem,
      groupBy = f.groupBy

    const tsWeekly = this.getTsWeekly(tsList, groupBy, startDate, endDate, contractType?.picode || null)
    this.tsDistinct$.next(tsWeekly)
    this.stateSvc.setGenInvReportPageState({ grid: { data: tsWeekly } })
    this.isDataLoaded = true
  }

  getTsWeekly(
    tsList: TimesheetParsed[] | undefined,
    groupBy: GenInvRepGroupBy,
    startDate: Date,
    endDate: Date,
    contractType: string | null
  ): TimesheetWeekly[] {
    const distinct: TimesheetWeekly[] = []
    tsList
      ?.filter(ts => ts?.tsstatus! > 0
        && (!contractType || contractType == this.contractSvc.getContractById(ts.conid)?.contractTypeAsPropItem?.picode))
      ?.forEach(ts => {
      if (!ts?.tsid) return
      const found = this.isGroupByRole
        ? distinct.find(d => d.conid === ts?.conid)
        : distinct.find(d => d.usid === ts?.usid)
      const treatedAsApproved = [TimesheetStatus.approved, TimesheetStatus.attGenerating, TimesheetStatus.attSent, TimesheetStatus.invAccepted,]
      const treatedAsSent = [TimesheetStatus.attSent, TimesheetStatus.invAccepted,]
      if (!found) {
        distinct.push({
          ...ts,
          startDate,
          endDate,
          tsids: [ts.tsid],
          tsList: [ts],
          groupBy,
          isApproved: treatedAsApproved.includes(ts.tsstatus!) ? 'yes' : 'no',
          isGenerating: ts.tsstatus == TimesheetStatus.attGenerating,
          isSent: treatedAsSent.includes(ts.tsstatus!) ? 'yes' : 'no',
          isInvAccepted: ts.tsstatus == TimesheetStatus.invAccepted ? 'yes' : 'no',
          isCurrencyMismatch: false,
          arrDoclink: ts.arrDoclink || [],
          departmentList: this.contractSvc.getDepartmentNamesByUser(ts.usid!),
          departmentListForFilter: this.contractSvc.getDepartmentNamesByUser(ts.usid!).join('$'),
          roleList: this.contractSvc.getRolesByUser(ts.usid!),
          roleListForFilter: this.contractSvc.getRolesByUser(ts.usid!).join('$'),
          companyList: this.contractSvc.getCompaniesByUser(ts.usid!),
          companyListForFilter: this.contractSvc.getCompaniesByUser(ts.usid!).join('$'),
          draftHtml: undefined,
        })
      } else {
        found.tsids.push(ts.tsid)
        found.tsList.push(ts)
        found.isGenerating = found.isGenerating || ts.tsstatus == TimesheetStatus.attGenerating
        if (found.isApproved != 'partially') {
          if (found.isApproved === 'no' && treatedAsApproved.includes(ts.tsstatus!)) {
            found.isApproved = 'partially'
          } else if (found.isApproved === 'yes' && !treatedAsApproved.includes(ts.tsstatus!)) {
            found.isApproved = 'partially'
          }
        }
        if (found.isSent != 'partially') {
          if (found.isSent === 'no' && treatedAsSent.includes(ts.tsstatus!)) {
            found.isSent = 'partially'
          } else if (found.isSent === 'yes' && !treatedAsSent.includes(ts.tsstatus!)) {
            found.isSent = 'partially'
          }
        }
        if (found.isInvAccepted != 'partially') {
          if (found.isInvAccepted === 'no' && ts.tsstatus == TimesheetStatus.invAccepted) {
            found.isInvAccepted = 'partially'
          } else if (found.isInvAccepted === 'yes' && ts.tsstatus != TimesheetStatus.invAccepted) {
            found.isInvAccepted = 'partially'
          }
        }
        if (!found.isCurrencyMismatch && found.currency !== ts.currency) {
          found.isCurrencyMismatch = true
          found.currency = (found.currency || '') + ' / ' + ts.currency
        }
        // hozzáadom az összes doc-ot, ami nincs még a listában
        ts.arrDoclink && found.arrDoclink?.push(...ts.arrDoclink.filter(doc => !found.arrDoclink?.find(foundDoc => foundDoc.fileid === doc.fileid)))
      }
    })
    return distinct
  }

  getSPParams({ usid }: { usid?: number } = {}): ListTimesheetsParams {
    const f = this.form.value
    const retVal: ListTimesheetsParams = {
      _conid: f.contract?.conid || null,
      _depid: f.dept?.depid || null,
      _usid: usid || f.user?.usid || null,
      _tsstatus: f.status?.map((s: any) => s.id) || null,
      _startdate: f.from && moment(f.from).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT),
      _enddate: f.to && moment(this.appSvc.getEndOfDay(f.to)).tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _contracttype: f.contractType?.picode || null,
    }
    if (!retVal._tsstatus?.length) retVal._tsstatus = null
    return retVal
  }


  getFG(): FormGroup {
    return this.fb.group({
      contract: [null],
      user: [null],
      dept: [null],
      status: [null],
      from: [this.filterSettings?.from || null, Validators.required],
      to: [this.filterSettings?.to || null, Validators.required],
      contractType: [this.filterSettings?.contractType || null],
      groupBy: [this.filterSettings?.groupBy || 'user' as GenInvRepGroupBy],
      hideSent: [this.filterSettings?.hideSent],
      hideInvoiceAccepted: [this.filterSettings?.hideInvoiceAccepted],
    })
  }

  canLoad(): ButtonStatus {
    if (this.form.invalid) return { disabled: true, title: 'A dátumok megadása kötelező' }
    return { disabled: false, title: 'Lekérdezés' }
  }

  canGenerate(ts?: TimesheetWeekly): ButtonStatus {
    if (!ts && this.selectedTSs.length === 0) return { disabled: true, title: 'Nincs kiválasztva Timesheet' }
    return { disabled: false, title: 'Számlamelléklet létrehozása' }
  }

  generate(dataItem: TimesheetWeekly[], sendEmail: boolean) {
    this.docSvc.generateTSDoc(dataItem, sendEmail, this.groupBy)
       ?.pipe(
         takeUntil(this._destroy$),
    //     map(response => response?.html || null),
     )
      ?.subscribe({
        next: html => {
          console.log('draft html', html);
          this.draft$.next(html?.html||null)
        },
        error: err => {
          console.error(err);
        }
    })
  }
    
  removeFile(event: RemoveFileClickedEvent): void {
    this.docSvc.removeContractFile(event.doc, event.data as ContractParsed)
  }

  getStatus(ts: TimesheetWeekly): {
    isApproved: string,
    isSent: string,
    isInvAccepted: string,
    approvedClass: string,
    invoicedClass: string,
    invAcceptedClass: string,
  } {
    const approvedClassMap: Record<YesNoPartial, string> = {
      yes: 'success',
      no: 'warning',
      partially: 'warning',
    }
    const sentClassMap: Record<YesNoPartial, string> = {
      yes: 'success',
      no: 'waiting',
      partially: 'danger',
    }
    const invAcceptedClassMap: Record<YesNoPartial, string> = {
      yes: 'success',
      no: 'waiting',
      partially: 'danger',
    }
    return {
      isApproved: ts.isApproved === 'yes' ? this.t7eApproved : (ts.isApproved === 'no' ? this.t7eNotApproved : this.t7ePartiallyApproved),
      approvedClass: approvedClassMap[ts.isApproved],
      isSent: ts.isSent === 'yes' ? this.t7eWeeklyTsSent : (ts.isSent === 'no' ? this.t7eWeeklyTsNotSent : this.t7eSomeWeeklyTsSent),
      invoicedClass: sentClassMap[ts.isSent],
      isInvAccepted: ts.isInvAccepted === 'yes' ? this.t7eInvAccepted : (ts.isInvAccepted === 'no' ? this.t7eInvNotAccepted : this.t7eSomeInvAccepted),
      invAcceptedClass: invAcceptedClassMap[ts.isInvAccepted],
    }
  }

  get prevPeriodEndDateText() {
    return ' ' + moment(this.fromFC.getRawValue()).tz(SQL_TIMEZONE).format(UI_DATE_MMM_D_FORMAT) + ' előtt'
  }

  lastWtsDayByUsid$ = combineLatest([
    this.tsDistinct$,
    this.contractSvc.allContracts$,
  ])
    .pipe(
      map(([tsDistinct, contracts]) => {
        const retVal: { [usid: number]: { date: moment.Moment | null, statusText: string | null, className: string | null } } = {}
        tsDistinct.forEach(wts => {
          if (!wts.usid) return
          const contractsForUser = contracts?.filter(c => c.usid === wts.usid)
          if (!contractsForUser?.length) return
          // find latest lastWtsDay among contracts
          const lastInvoicedDay = contractsForUser
            ?.map(c => c.lastWtsDay)
            ?.reduce((prev, curr) => {
              if (!prev) return curr
              if (!curr) return prev
              return prev > curr ? prev : curr
            })
          const className = lastInvoicedDay
            && (
              // class is danger, if the date is not the toFC date + 1 day
              moment(lastInvoicedDay).tz(SQL_TIMEZONE).add(1, 'day').format(SQL_DATE_FORMAT)
              !== moment(this.fromFC.value).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT)
              &&
              // class is danger, if the date is not the toFC date
              moment(lastInvoicedDay).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT)
              !== moment(this.toFC.value).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT)
            )
            ? 'danger'
            : 'success'
          retVal[wts.usid] = {
            date: lastInvoicedDay || null,
            statusText: this.t7eLastWtsDay + lastInvoicedDay?.format(SQL_MONTH_DAY_FORMAT) || null,
            className,
          }
        })
        return retVal
      })
  )
  
  get selectedTSs(): TimesheetWeekly[] {
    return this.selectedContractIds
      .map(id => (this.tsDistinct$.value.find(t => t.conid === id)!))
  }
  setPdfSent(ts: TimesheetWeekly | TimesheetWeekly[]): void {
    const tsids = Array.isArray(ts)
      ? ts.reduce((prev, curr, arr) => prev.concat(curr.tsids), [] as number[])
      : ts.tsids
    const usid = Array.isArray(ts)
      ? undefined
      : ts.usid as number
    this.setStatus(tsids, TimesheetStatus.attSent, undefined, usid, moment(this.fromFC.getRawValue()))
  }

  setInvoiceAccepted(ts: TimesheetWeekly | TimesheetWeekly[]): void {
    const tsids = Array.isArray(ts)
      ? ts.reduce((prev, curr, arr) => prev.concat(curr.tsids), [] as number[])
      : ts.tsids
    const usid = Array.isArray(ts)
      ? undefined
      : ts.usid as number
    this.setStatus(tsids, TimesheetStatus.invAccepted, undefined, usid, moment(this.fromFC.getRawValue()))
  }

  private setStatus(tsids: number[], status: number, savecomment: string | null = null, usid: number | undefined = undefined, setWtsLastDate: Moment | null = null): void {
    if (!tsids) {
      this.notifSvc.addObservableNotif({ msg: 'Mentés hiba. Ismeretlen státusz', class: 'danger' })
      return
    }
    const params: RegisterTsStatus = {
      _tsids: JSON.stringify(tsids),
      _tsstatus: status,
      _savecomment: savecomment,
      _enddate: setWtsLastDate?.tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT),
    }
    this.tsSvc.registerTsStatus(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: res => {
          console.log('registerTsStatus válasz', res);
          this.reload({ usid })
        },
        error: err => {
          console.error('registerTsStatus error', err);
          this.notifSvc.addObservableNotif({ msg: 'Mentés hiba', class: 'danger' })
        }
      })
  }


  canSetPdfSent(timesheets: Timesheet | Timesheet[]): ButtonStatus {
    if (!Array.isArray(timesheets)) {
      const conid = timesheets.conid!
      timesheets = this.aTsList$ForConid[timesheets.conid!].value
      if (!timesheets) {
        console.warn(`this.aTsList$ForConid[${conid}].value null`)
        return { disabled: true, title: 'Ismeretlen timesheetek. Talán törölve lett a start form' }
      }
    }
    if (!timesheets.length) return { disabled: true, title: 'Nincs Timesheet kiválasztva' }
    if (timesheets.some((ts: Timesheet) => ts?.tsstatus == TimesheetStatus.deleted)) return { disabled: true, title: 'Törölve van' }
    if (timesheets.some((ts: Timesheet) => ts?.tsstatus == TimesheetStatus.disabled)) return { disabled: true, title: 'A Timesheet le van tiltva' }
    const notApprovedStatuses = [TimesheetStatus.atCrewMember, TimesheetStatus.atDeptHead, TimesheetStatus.deptHeadApproved, TimesheetStatus.atProdMgr]
    if (timesheets.some((ts: Timesheet) => notApprovedStatuses.includes(ts?.tsstatus!))) return { disabled: true, title: 'Még nincs jóváhagyva' }
    if (timesheets.every((ts: Timesheet) => ts?.tsstatus == TimesheetStatus.attSent)) return { disabled: true, title: 'Már kiküldve emailben' }
    return { disabled: false, title: 'Ezzel regisztrálhatod, hogy a Timesheetet kiküldted emailben' }
  }
  canSetInvoiceAccepted(timesheets: Timesheet | Timesheet[]): ButtonStatus {
    if (!Array.isArray(timesheets)) timesheets = [timesheets]
    if (!timesheets.length) return { disabled: true, title: 'Nincs Timesheet kiválasztva' }
    if (timesheets.some((ts: Timesheet) => ts?.tsstatus == TimesheetStatus.deleted)) return { disabled: true, title: 'Min 1 timesheet törölve van' }
    if (timesheets.some((ts: Timesheet) => ts?.tsstatus == TimesheetStatus.disabled)) return { disabled: true, title: 'Min 1 timesheet le van tiltva' }
    const notApprovedStatuses = [TimesheetStatus.atCrewMember, TimesheetStatus.atDeptHead, TimesheetStatus.deptHeadApproved, TimesheetStatus.atProdMgr]
    if (timesheets.some((ts: Timesheet) => notApprovedStatuses.includes(ts?.tsstatus!))) return { disabled: true, title: 'Még nincs jóváhagyva' }
    //if (timesheets.some((ts: Timesheet) => ts?.tsstatus! < 65)) return { disabled: true, title: 'Még nincs kiküldve a számlamelléklet' }
    if (timesheets.every((ts: Timesheet) => ts?.tsstatus == TimesheetStatus.invAccepted)) return { disabled: true, title: 'Számla már befogadva' }
    return { disabled: false, title: 'Ezzel regisztrálhatod, hogy a számla be lett fogadva' }
  }

  
  /** USER PREFERENCES */

  get filterSettings() { return this.stateSvc.genInvReportPageState$?.value?.filters }
  get gridSettings() {
    return this.stateSvc.genInvReportPageState$.value?.grid
  }
  get skip() { return this.gridSettings?.state?.skip || 0 }
  get sort() { return this.gridSettings?.state?.sort || [] }
  get filter() { return this.gridSettings?.state?.filter || { filters: [], logic: 'and' } }

  get selectedContractIds() { return this.gridSettings?.selectedContractIds || [] }
  set selectedContractIds(val: number[]) {
    this.stateSvc.setGenInvReportPageState({ grid: { selectedContractIds: val, } })
  }
  get expandedDetailKeys() { return this.gridSettings?.expandedDetailKeys || [] }
  set expandedDetailKeys(val: number[]) {
    this.stateSvc.setGenInvReportPageState({ grid: { expandedDetailKeys: val, } })
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.stateSvc.setGenInvReportPageState({ grid: { state } })
  }

  filterChanged(event: CompositeFilterDescriptor): void {
    this.stateSvc.setGenInvReportPageState({
      grid: { state: { skip: 0 } }
    })
  }

  public onColReorder(e: ColumnReorderEvent): void {
    // set the new orderIndex to all affected columns
    const newColumnsConfig: any = getReorderedConfig(this.grid, e)
    this.stateSvc.setGenInvReportPageState({ grid: { columnsConfig: newColumnsConfig } })
    //console.log(this.stateSvc.manageContractsPageState$.value?.grid?.columnsConfig)
  }

  public onColResized(e: ColumnResizeArgs[]): void {
    const newConfigs = getResizedConfig(e)
    newConfigs.forEach((newConfigs) => {
      this.stateSvc.setGenInvReportPageState({ grid: { columnsConfig: newConfigs } });
    })
  }

  getWidth(field: string): number | undefined {
    const columnsConfig = this.gridSettings?.columnsConfig || {}
    return columnsConfig[field]?.width
  }


  toJson(o: any): string {
    return JSON.stringify(o)
  }

  /** T7E */
  t7eLastWtsDay = this.t7e.getTranslation('pipe', 'last-wts-day', 'innerText', null, null, 'Utolsó WTS: ')
  t7eApproved = this.t7e.getTranslation('pipe', 'approved', 'innerText', null, null, 'Jóváhagyva')
  t7eNotApproved = this.t7e.getTranslation('pipe', 'not-approved', 'innerText', null, null, 'Nincs jóváhagyva')
  t7ePartiallyApproved = this.t7e.getTranslation('pipe', 'partially-approved', 'innerText', null, null, 'Részben jóváhagyva')
  t7eWeeklyTsSent = this.t7e.getTranslation('pipe', 'weekly-ts-sent', 'innerText', null, null, 'WTS kiküldve')
  t7eWeeklyTsNotSent = this.t7e.getTranslation('pipe', 'weekly-ts-not-sent', 'innerText', null, null, 'WTS nincs kiküldve')
  t7eSomeWeeklyTsSent = this.t7e.getTranslation('pipe', 'some-weekly-ts-sent', 'innerText', null, null, 'Néhány napra WTS kiküldve')
  t7eInvAccepted = this.t7e.getTranslation('pipe', 'invoice-accepted', 'innerText', null, null, 'Számla befogadva')
  t7eInvNotAccepted = this.t7e.getTranslation('pipe', 'invoice-not-accepted', 'innerText', null, null, 'Számla nincs befogadva')
  t7eSomeInvAccepted = this.t7e.getTranslation('pipe', 'some-invoice-accepted', 'innerText', null, null, 'Néhány napra számla befogadva')

}
