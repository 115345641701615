import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { AppService, DbCallError } from '../app.service';
import { DataService, FlexTables } from '../data.service';
import { HandleProdParameters, Production, ProductionParsed } from '../models/production';
import { NotificationService } from '../util/notifications/notification.service';
import { T7eService } from '../t7e/t7e.service';
import { Dispo, ExchangeRate, Holiday, ShootingDay } from '../models/dispo';
import { Moment } from 'moment-timezone';
import * as moment from 'moment-timezone';
import { ParserService, SQL_DATETIME_FORMAT, SQL_DATE_FORMAT, SQL_TIMEZONE, SQL_TIME_FORMAT } from '../parser.service';
import { HandleShootingLocationParams, ShootingLocation } from '../models/location';
import { HandlePropItemsParams } from '../models/handle-table-properties-params';
import { ExtraOttaValues } from '../report/weekly-otta/weekly-otta.component';
import { config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {
  prodDefaults: ProductionParsed | null = null
  readonly prodDefaults$ = new BehaviorSubject<ProductionParsed | null>(null)
  readonly loadProdDefaultsError$ = new BehaviorSubject<DbCallError>(null)
  readonly saveProdDefaultsError$ = new BehaviorSubject<DbCallError>(null)
  readonly savingProdDefaults$ = new BehaviorSubject<boolean>(false)

  readonly days$ = new BehaviorSubject<Dispo[]>([])
  readonly daysLoading$ = new BehaviorSubject<boolean>(false)
  readonly daysLoadingError$ = new BehaviorSubject<DbCallError>(null)
  readonly daySaving$ = new BehaviorSubject<boolean>(false)
  readonly daysSavingError$ = new BehaviorSubject<DbCallError>(null)
  readonly currencyExchangeRates$ = new BehaviorSubject<ExchangeRate[] | null>(null)
  readonly currencyExchangeRate$ = new BehaviorSubject<ExchangeRate | null>(null)
  readonly currencyExchangeRatesError$ = new BehaviorSubject<DbCallError>(null)
  readonly currencyExchangeRatesLoading$ = new BehaviorSubject<boolean>(false)
  readonly todaysCurrencyExchangeRate$ = new BehaviorSubject<ExchangeRate | null>(null)
  readonly todaysCurrencyExchangeRateError$ = new BehaviorSubject<DbCallError>(null)
  readonly todaysCurrencyExchangeRateLoading$ = new BehaviorSubject<boolean>(false)
  
  readonly locations$ = new BehaviorSubject<ShootingLocation[] | null>(null)
  readonly locationsLoading$ = new BehaviorSubject<boolean>(false)
  readonly locationsLoadingError$ = new BehaviorSubject<DbCallError>(null)
  readonly locationSaving$ = new BehaviorSubject<boolean>(false)
  readonly locationSavingError$ = new BehaviorSubject<DbCallError>(null)

  readonly holidays$ = new BehaviorSubject<Holiday[] | null>(null)
  readonly holidaysLoading$ = new BehaviorSubject<boolean>(false)
  readonly holidaysLoadingError$ = new BehaviorSubject<DbCallError>(null)
  readonly holidaySavingError$ = new BehaviorSubject<DbCallError>(null)

  constructor(
    private dataSvc: DataService,
    private appSvc: AppService,
    private notifSvc: NotificationService,
    private t7e: T7eService,
    private parser: ParserService,
  ) { 
    this.mapLoadedProdDefaultsArray = this.mapLoadedProdDefaultsArray.bind(this)
    this.mapLoadedProdDefaults = this.mapLoadedProdDefaults.bind(this)

    dataSvc.initData$.subscribe(appData => {
      this.prodDefaults$.next(this.mapLoadedProdDefaultsArray(appData?.data?.list_productions || null))
    })
    dataSvc.initData$
      .pipe(
        map(appData => appData?.data?.list_propertyitems
          ?.filter(pi => pi.propid === 80)
          .map(this.parser.parseDbLocation)
          .sort((a, b) => a.locName.localeCompare(b.locName))
          || []
        )
    )
    .subscribe(this.locations$)

    this.prodDefaults$.subscribe(pd => {
      this.prodDefaults = pd
      this.prodDefaults && this.dataSvc.setCache(FlexTables.productions, this.prodDefaults)
    })

    this.currencyExchangeRates$.pipe(
      map(rates => rates?.sort((a, b) => b.date.getTime() - a.date.getTime())[0] || null)
    ).subscribe(this.currencyExchangeRate$)
  }

  get gracePeriod() { return this.prodDefaults?.graceperiod || 0 }

  parseProd(prodRawFromSql: Production): ProductionParsed {
    const retVal: ProductionParsed = {
      ...prodRawFromSql,
      ...prodRawFromSql.details
        ?.reduce((prev, curr) => Object.assign(prev, { [curr.propcode]: curr.propvalue }), {}),
      dPreparationstartday: prodRawFromSql.preparationstartday ? new Date(prodRawFromSql.preparationstartday) : null,
      dShootingstart: prodRawFromSql.shootingstart ? new Date(prodRawFromSql.shootingstart) : null,
      dShootingend: prodRawFromSql.shootingend ? new Date(prodRawFromSql.shootingend) : null,
    }
    //@ts-ignore
    retVal.otrate = JSON.parse(retVal.otrate)
    return retVal
  }

  listProd() {
    const retVal = this.dataSvc.listProd({})
    .pipe(map(this.mapLoadedProdDefaultsArray))
    retVal.subscribe({
      next: prods => {
        this.prodDefaults$.next(this.prodDefaults)
      },
      error: err => {
        this.notifSvc.addObservableNotif({ msg: this.t7eErrorLoadingProdDefaults, class: 'danger', duration: 3000, })
      }
    })
    return retVal
  }

  handleProd(params: HandleProdParameters) {
    const retVal = this.dataSvc.handleProd(params)
      .pipe(map(this.mapLoadedProdDefaultsArray))
    retVal.subscribe({
      next: prod => {
        this.prodDefaults$.next(prod)
      },
      error: err => {
        this.notifSvc.addObservableNotif({ msg: this.t7eErrorSavingProdDefaults, class: 'danger', duration: 3000, })
      }
    })
    return retVal
  }

  mapLoadedProdDefaultsArray(prods: Production[] | null): ProductionParsed | null {
    return this.mapLoadedProdDefaults(prods?.[0] || null)
  }
  mapLoadedProdDefaults(prod: Production | null): ProductionParsed | null {
    const prodParsed = !prod
      ? null
      : this.parseProd(prod)
    return prodParsed
  }

  fetchShootingLocations() {
    this.locationsLoading$.next(true)
    this.locationsLoadingError$.next(null)
    const retVal = this.dataSvc.listLocations()
    retVal.subscribe({
      next: locations => {
        this.locationsLoading$.next(false)
        this.locationsLoadingError$.next(false)
        this.locations$.next(locations)
      },
      error: err => {
        this.locationsLoading$.next(false)
        this.locationsLoadingError$.next(err)
      },
    })
    return retVal
  }

  saveShootingLocation(location: HandleShootingLocationParams) {
    this.locationSaving$.next(true)
    this.locationSavingError$.next(null)
    const retVal = this.dataSvc.handleLocation(location)
    retVal.subscribe({
      next: locations => {
        this.locationSaving$.next(false)
        this.locationSavingError$.next(false)
        const locatiionsUpdated = this.appSvc.replaceDocItems(locations, this.locations$.value, 'locId')
        this.locations$.next(locatiionsUpdated)
      },
      error: err => {
        this.locationSaving$.next(false)
        this.locationSavingError$.next(err)
      },
    })
    return retVal
  }

  enableDisableLocation(locId: number, enabled: boolean) {
    this.locationSaving$.next(true)
    this.locationSavingError$.next(null)
    const loc = this.locations$.value?.find(l => l.locId === locId)
    if (!loc) {
      this.locationSaving$.next(false)
      this.locationSavingError$.next({ msg: 'Location not found', code: 0 })
      return
    }
    this.saveShootingLocation({ ...loc, enabled })
  }

  fetchDays() {
    this.daysLoadingError$.next(null)
    this.daysLoading$.next(true)
    const retVal = this.dataSvc.ListDispo({ })
    retVal.subscribe({
      next: days => {
        this.daysLoading$.next(false)
        this.daysLoadingError$.next(false)
        this.days$.next(days)
      },
      error: err => {
        this.daysLoading$.next(false)
        this.daysLoadingError$.next(err)
      },
    })
    return retVal
  }

  saveDay(day: ShootingDay) {
    this.daySaving$.next(true)
    this.daysSavingError$.next(null)
    // startDateTime = day.date + day.crewCallTime
    const startDateTime = moment(moment(day.date).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT) + ' ' + moment(day.crewCallTime).tz(SQL_TIMEZONE).format(SQL_TIME_FORMAT))
    let endDateTime = day.cameraWrapTime
      ? moment(moment(day.date).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT) + ' ' + moment(day.cameraWrapTime).tz(SQL_TIMEZONE).format(SQL_TIME_FORMAT))
      : null
    // ha a wrap time kisebb mint a call time, akkor másnapra esik
    if (endDateTime?.isBefore(startDateTime)) endDateTime = endDateTime.add(1, 'day')
    const retVal = this.dataSvc.handleDispo({
      _shid: day.shId || null,
      _data: JSON.stringify({
        location: day.locCode,
      }),
      _shstartdate: startDateTime.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _shenddate: endDateTime?.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT) || null,
    })
    retVal.subscribe({
      next: days => {
        this.daySaving$.next(false)
        this.daysSavingError$.next(false)
        const daysUpdated = this.appSvc.replaceDocItems(days, this.days$.value, 'shId')
        this.days$.next(daysUpdated)
      },
      error: err => {
        this.daySaving$.next(false)
        this.daysSavingError$.next(err)
      },
    })
    return retVal
  }

  saveExtraOtta(dayId: number | null, extra: ExtraOttaValues, startTime: Moment, endTime: Moment) {
    this.daySaving$.next(true)
    this.daysSavingError$.next(null)
    const retVal = this.dataSvc.handleDispo({
      _shid: dayId,
      _data: JSON.stringify({
        ottaextra1: extra.extra1,
        ottaextra2: extra.extra2,
        ottaextra3: extra.extra3,
      }),
      _shstartdate: startTime.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _shenddate: endTime.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
    })
    retVal.subscribe({
      next: days => {
        this.daySaving$.next(false)
        this.daysSavingError$.next(false)
      },
      error: err => {
        this.daySaving$.next(false)
        this.daysSavingError$.next(err)
      },
    })
    return retVal
  }

  fetchHolidays() {
    this.holidaysLoadingError$.next(null)
    this.holidaysLoading$.next(true)
    const retVal = this.dataSvc.ListDispo({ propId: 79, dayId: undefined }) // 79 = holidays
      .pipe(
        map(holidays => holidays
          .filter(h => h.values.filter(v => v.propCode == 'daytype' &&  v.propValue =='holy').length > 0)
          .map(h => ({
            dayId: h.shId || null,
            date: h.startDate,
          }))
          .sort((a,b) => b.date.getTime() - a.date.getTime())
        )
      )  
    retVal.subscribe({
      next: holidays => {
        this.holidaysLoading$.next(false)
        this.holidaysLoadingError$.next(false)
        this.holidays$.next(holidays)
      },
      error: err => {
        this.holidaysLoading$.next(false)
        this.holidaysLoadingError$.next(err)
      },
    })
    return retVal
  }

  saveHoliday(holiday: Holiday, deleteHoliday = false) {
    //const dayId = this.days$.value?.find(d => moment(d.startDate).isSame(date, 'day'))?.shId || null
    this.daySaving$.next(true)
    this.holidaySavingError$.next(null)
    const retVal = this.dataSvc.handleDispo({
      _shid: holiday.dayId || null,
      _data: JSON.stringify({ daytype: deleteHoliday ? null :'holy'}),
      _shstartdate: moment(holiday.date).tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _shenddate: moment(holiday.date).add(1, 'day').tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
    })
    retVal.subscribe({
      next: holidays => {
        this.holidaySavingError$.next(false),
        this.fetchHolidays()
          .subscribe({
            next: () => this.daySaving$.next(false),
            error: err => this.holidaySavingError$.next(err),
          })
      },
      error: err => {
        this.daySaving$.next(false)
        this.holidaySavingError$.next(err)
      },
    })
    return retVal
  }

  deleteHoliday(dayId: number) {
    return this.saveHoliday({dayId, date: new Date()}, true)
  }

  fetchTodaysExchangeRate() {
    if (!config.CONFIG_MULTIPLE_CURRENCIES) return
    this.todaysCurrencyExchangeRateLoading$.next(true)
    this.todaysCurrencyExchangeRateError$.next(null)
    const retVal = this.dataSvc.ListDispo({ propId: 38, dayId: 0 }) // 38 = currency exchange rates, dayId = 0 means latest value
    retVal.subscribe({
      next: rates => {
        this.todaysCurrencyExchangeRateLoading$.next(false)
        this.todaysCurrencyExchangeRateError$.next(false)
        this.todaysCurrencyExchangeRate$.next({ rate: rates[0].values?.[0].propValue || null, date: rates[0].startDate, dayId: rates[0].shId || null  })
      },
      error: err => {
        this.todaysCurrencyExchangeRateLoading$.next(false)
        this.todaysCurrencyExchangeRateError$.next(err)
        this.todaysCurrencyExchangeRate$.next(null)
      },
    })
  }

  fetchCurrencyExchangeRates() {
    if (!config.CONFIG_MULTIPLE_CURRENCIES) return null
    this.currencyExchangeRatesError$.next(null)
    this.currencyExchangeRatesLoading$.next(true)
    const retVal = this.dataSvc.ListDispo({ propId: 38, dayId: undefined }) // 38 = currency exchange rates
      .pipe(map(rates => rates
        .filter(r => r.values.filter(v => v.propValue !== null).length > 0) // törléskor a propValue null lesz, azt nem akarjuk
        .map(d => ({ dayId: d.shId || null, date: d.startDate, rate: d.values?.[0].propValue }))
        .sort((a, b) => a.date.getTime() - b.date.getTime())
      )
      )
    retVal.subscribe({
      next: rates => {
        this.currencyExchangeRatesLoading$.next(false)
        this.currencyExchangeRatesError$.next(false)
        this.currencyExchangeRates$.next(rates)
      },
      error: err => {
        this.currencyExchangeRatesLoading$.next(false)
        this.currencyExchangeRatesError$.next(err)
      },
    })
    return retVal
  }

  saveCurrencyExchangeRate(dayId: number | null, rate: number | null, date: Moment) {
    this.currencyExchangeRatesError$.next(null)
    this.daySaving$.next(true)
    const retVal = this.dataSvc.handleDispo({
      _shid: dayId,
      _data: JSON.stringify({currate: rate}),
      _shstartdate: date.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _shenddate: date.tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
    })
    retVal.subscribe({
      next: rates => {
        this.daySaving$.next(false)
        this.currencyExchangeRatesError$.next(false)
        this.currencyExchangeRates$.next(rates.map(d => ({ dayId: d.shId || null, date: d.startDate, rate: d.values?.[0].propValue}) ))
      },
      error: err => {
        this.daySaving$.next(false)
        this.currencyExchangeRatesError$.next(err)
      },
    })
    return retVal
  }

  getCurrencyExchangeRateByDate(date: Date): number {
    const rates = this.currencyExchangeRates$.value?.sort((a, b) => a.date.getTime() - b.date.getTime())
    if (!rates) {
      throw 'Ismeretlen árfolyamok'
    }

    let nearestIndex = -1;
    for (let i = 0; i < rates.length; i++) {
      if (moment(date).isSame(rates[i].date, 'day')) {
        nearestIndex = i
        break;
      } else if (moment(date).isAfter(rates[i].date, 'day')) {
        nearestIndex = i - 1;
        break;
      }
    }

    if (nearestIndex === -1) {
      nearestIndex = rates.length - 1;
      console.log(`Nearest date and rate before ${date}: ${rates[nearestIndex].date} - ${rates[nearestIndex].rate}`);
    }

    return rates[nearestIndex].rate!
  }

  getCurrency() {
    return this.prodDefaults?.currency || 'HUF'
  }

  get isPrep() {
    // if today is before shooting start date
    if (!this.prodDefaults?.dShootingstart) return false
    return this.prodDefaults.dShootingstart.getTime() > Date.now()
  }

  /** T7E */
  t7eErrorLoadingProdDefaults = this.t7e.getTranslation('prod.service', 'error-loading-prod-defaults', 'innerText', null, null, 'Hiba a gyártás alapadatainak betöltése közben')
  t7eErrorSavingProdDefaults = this.t7e.getTranslation('prod.service', 'error-saving-prod-defaults', 'innerText', null, null, 'Hiba a gyártás alapadatainak mentése közben')
}


